import { FC, useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import ProcessExecutions from "../../../api/process_executions";
import { User } from "../../../model";
import { ProcessExecution } from "../../../model/ProcessExecution";
import {
  RenderNextTask,
  RenderTaskTitle,
} from "../../../hooks/useGlobalRoleContext";
import { useAlert } from "../../../lib/alert";
import {
  DataGrid,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { CommonDatagridWrapper } from "../../../components/elements";
import ProcessPhaseChip from "../../process/components/ProcessPhaseChip";
import ProcessExecutionUpdated from "../../process/components/ProcessExecutionStarted";
import ProcessProgress from "../../process/components/ProcessProgress";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import messages from "../../process/messages";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../components/navigation/_paths";
import { AccountProfileViewer } from "../AccountProfilePage";
import getUserFullName from "../../activity-log/utils/getUserFullName";

// TODO: Need to pull column definition out of the fields that the
// process_execution object exposes by API

type AccountProcessExecutionsProps = {
  organizationId?: string;
  subjectId?: string;
  onDataLoaded?: (processes: ProcessExecution[]) => void;
  disableExecutionLinks?: boolean;
  viewer: AccountProfileViewer;
};

const AccountProcessExecutions: FC<AccountProcessExecutionsProps> = ({
  subjectId,
  onDataLoaded,
  viewer,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { organization } = useGlobalOrganizationContext();
  const [
    processExecutionsPaginationModel,
    setProcessExecutionsPaginationModel,
  ] = useState({ pageSize: 4, page: 0 });
  const { handleRejectionWithError } = useAlert();
  const navigateToExecution = useCallback(
    (processExecution: ProcessExecution) => {
      if (viewer === AccountProfileViewer.Admin) {
        navigate(
          PATHS.EXECUTION.linkTo(
            processExecution.process_id,
            processExecution.id,
          ),
        );
      } else {
        navigate(PATHS.ACCOUNT_EXECUTION.linkTo("", processExecution.id));
      }
    },
    [navigate, viewer],
  );

  // TODO: Need to pull column definition out of the fields that the
  // process_execution object exposes by API
  const processExecutionColumns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      hide: true,
    },
    {
      field: "name",
      headerName: "Title",
      sortable: true,
      minWidth: 200,
      headerClassName: "process-grid-header",
      renderCell: RenderTaskTitle,
    },
    {
      field: "phase",
      headerName: "Phase",
      sortable: true,
      disableColumnMenu: true,
      minWidth: 110,
      maxWidth: 150,
      headerClassName: "process-grid-header",
      renderCell: (params: GridRenderCellParams) => {
        return <ProcessPhaseChip {...params.row} />;
      },
    },
    {
      field: "progress",
      headerName: "Progress",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      headerClassName: "process-grid-header",
      renderCell: (params: GridRenderCellParams) => {
        return <ProcessProgress processExecution={params.row} />;
      },
    },
    {
      field: "nextTask",
      headerName: "Next Task",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      headerClassName: "process-grid-header",
      renderCell: (params: GridRenderCellParams) =>
        RenderNextTask(params, navigateToExecution),
    },
    {
      field: "assigned",
      headerName: "Assigned",
      minWidth: 150,
      headerClassName: "process-grid-header",
      valueGetter: (
        params: GridValueGetterParams<ProcessExecution>,
      ): string => {
        const assignee: User | undefined =
          params.row.next_task_instance?.assignee;
        if (assignee) {
          return getUserFullName(assignee);
        }
        return params.row.next_task_instance?.role?.name || "";
      },
    },
    {
      field: "updated_at",
      type: "date",
      headerName: "Date Updated",
      sortable: true,
      width: 150,
      headerClassName: "process-grid-header",
      renderCell: (params: GridRenderCellParams) => {
        return <ProcessExecutionUpdated {...params.row} />;
      },
      valueGetter: (params: GridValueGetterParams) => new Date(params.value),
    },
  ];

  // state to manage current process executions for organization
  const [processExecutionList, setProcessExecutionList] = useState<
    Array<ProcessExecution>
  >([]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [processExecutionSelectionModel, setProcessExecutionSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const fetchProcessExecutions = useCallback((): Promise<
    Array<ProcessExecution>
  > => {
    const idToUse = subjectId ? subjectId : organization ? organization.id : "";
    return ProcessExecutions.bySubjectId(idToUse).then(
      (processes: Array<ProcessExecution>) => {
        const filteredProcessesArray: Array<ProcessExecution> = [];

        processes.forEach((process) => {
          if (process.process_name.toLowerCase() === "submit an idea")
            process.process_name = "Quick Explore";
          filteredProcessesArray.push(process);
        });

        setProcessExecutionList(processes);
        return processes;
      },
      (err) => {
        console.error(
          `Error listing process executions from "bySubjectId" -- ${err}`,
        );
        return [];
      },
    );
  }, [subjectId, organization]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchProcessExecutions().then((processExecutions) => {
            // Cancel any queued calls now that we've finished loading
            reload.cancel();

            setProcessExecutionList(processExecutions);
            setIsLoaded(true);
            if (onDataLoaded) {
              onDataLoaded(processExecutions);
            }
          }, handleRejectionWithError("Failed to load user role ids"));
        },
        5000,
        { leading: true },
      ),
    [fetchProcessExecutions, handleRejectionWithError, onDataLoaded],
  );

  useEffect(() => {
    // default organization & user is empty
    // the result is that "fetchRoleIdsForUser" throws an Unauthorized exception
    // which leads to poor UX
    reload();
  }, [reload]);
  const executionsMsg = intl.formatMessage(messages.inProgressExecutions.props);

  const processExecutionDataGrid = (
    <DataGrid
      disableColumnSelector={true}
      rows={processExecutionList}
      columns={processExecutionColumns}
      columnVisibilityModel={{
        id: false,
        description: false,
      }}
      autoHeight
      pageSizeOptions={[10]}
      paginationModel={processExecutionsPaginationModel}
      onPaginationModelChange={(paginationModel) =>
        setProcessExecutionsPaginationModel(paginationModel)
      }
      onRowSelectionModelChange={(newSelectionModel) => {
        setProcessExecutionSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={processExecutionSelectionModel}
    />
  );

  return (
    <CommonDatagridWrapper
      loaded={processExecutionDataGrid}
      isLoaded={isLoaded}
      title={executionsMsg}
      variant="card"
      PageHeaderProps={{ showAccountMenu: false }}
    />
  );
};

export default AccountProcessExecutions;
