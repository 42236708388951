import { BarChart } from "@mui/x-charts";
import HeroContainer from "../../../../components/elements/HeroContainer";
import { useTheme } from "@mui/material/styles";
import { DashboardElementBarChart } from "../../../../model/Dashboard";
import { useMemo } from "react";
import { Container, Skeleton } from "@mui/material";
import pluralize from "pluralize";

type BarChartCardProps = {
  element: DashboardElementBarChart;
};

export type BarChartData = {
  data: number[];
  label: string;
  valueFormatter?: (value: number) => string;
}[];

const formatData = (element: DashboardElementBarChart): BarChartData => {
  const getValueFormatter = (descriptor?: string) => {
    return (value: number) =>
      `${value ?? 0} ${pluralize(descriptor ?? "", value ?? 0)}`;
  };

  return (
    element.data?.map((elementData) => {
      const valueFormatter = getValueFormatter(elementData.descriptor);
      return {
        data: elementData.data,
        label: elementData.label,
        valueFormatter,
      };
    }) ?? []
  );
};

export const BarChartCard = ({ element }: BarChartCardProps) => {
  const theme = useTheme();
  const metricData = useMemo<BarChartData>(
    () => formatData(element),
    [element],
  );

  if (metricData.length == 0) return <BarChartCardSkeleton />;

  return (
    <HeroContainer displayTopographic sx={{ minWidth: "22em" }}>
      <BarChart
        height={300}
        margin={{ right: 100, top: 20, bottom: 20 }}
        xAxis={[
          {
            // These settings are to hide the x-axis label in the tooltip. Data and scaleType are required but hideTooltip is all we care about.
            hideTooltip: true,
            scaleType: "band",
            data: [""],
          },
        ]}
        bottomAxis={null}
        slotProps={{
          legend: {
            direction: "column",
            position: {
              vertical: "middle",
              horizontal: "right",
            },
          },
        }}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.success.main,
          theme.palette.success.light,
        ]}
        series={metricData}
      />
    </HeroContainer>
  );
};

export const BarChartCardSkeleton = () => (
  <HeroContainer
    displayTopographic
    sx={{
      height: 1,
      width: 1,
      display: "flex",
      minWidth: "22em",
    }}
  >
    <Container
      disableGutters
      sx={{
        width: 0.5,
        display: "flex",
        alignItems: "flex-end",
        pb: 4,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ height: "200px", width: 0.25, ml: 6 }}
      />
      <Skeleton
        variant="rectangular"
        sx={{ height: "150px", width: 0.25, ml: 1 }}
      />
    </Container>
    <Container
      disableGutters
      sx={{
        width: 0.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        px: 2,
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
    </Container>
  </HeroContainer>
);
