import { FC, useEffect, useState, useCallback, useMemo, useRef } from "react";
import debounce from "lodash.debounce";
import ProcessExecutions from "../../../api/process_executions";
import { Organization, User } from "../../../model";
import {
  ExecutionType,
  ProcessExecution,
} from "../../../model/ProcessExecution";
import { useGlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { RenderNextTask } from "../../../hooks/useGlobalRoleContext";
import { useAlert } from "../../../lib/alert";
import {
  Filter,
  PagedResponse,
  ProcessExecutionAPI,
  toFilter,
} from "../../../api";
import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSearchIcon,
} from "@mui/x-data-grid";
import ProcessExecutionDataGrid, {
  DEFAULT_GRID_COLUMNS,
  ProcessExecutionDataGridProps,
} from "./ProcessExecutionDataGrid";
import ProcessGridSelector from "./ProcessGridSelector";
import { useIntl } from "react-intl";
import messages from "../messages";
import environment from "../../../util/environment";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import {
  AssignmentIndOutlined,
  CheckCircleOutline,
  CloudDownload,
  DeleteOutlined,
  ListAlt,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TestModeToggle from "../../../components/layout/top-bar/components/TestModeToggle";
import useFeature from "../../../hooks/useFeature";
import ProcessPhaseChip from "./ProcessPhaseChip";
import DatagridToolbar from "../../../components/elements/datagrid/DatagridToolbar";
import { DeleteDialog } from "../../../components/dialogs";
import ToolbarButton from "../../../components/elements/datagrid/ToolbarButton";
import { PageHeader } from "../../../components/layout/page";
import { ExecutionState, Process } from "../../../model/Process";
import { TEST_ROW_CLASS_NAME } from "../../../components/elements/datagrid/StyledDataGrid";
import ConfirmationModal from "./confirmation-modal/ConfirmationModal";
import { ChooseAccountDialog } from "../../accounts/components";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import AssignDialog from "../../../components/dialogs/AssignDialog";
import UserSelection from "../../../components/core/Selection/UserSelection";
import DatagridToolbarMenuButton from "../../../components/elements/datagrid/DatagridToolbarMenuButton";
import { AxiosRequestConfig } from "axios";
import downloadApiFile from "../../../util/downloadApiFile";
import TestModeStatus from "../../../components/layout/top-bar/components/TestModeStatus";
import { onProcessFilterAtom, testModeAtom } from "../state";
import { useAtom } from "jotai";
import ProcessChip from "./ProcessChip";

// TODO: Need to pull column definition out of the fields that the
// process_execution object exposes by API

type OrganizationProcessExecutionsProps = {
  currentOrganization?: Organization;
};

const POLL_DURATION = 30000;

const requestProcessExecutions = async (
  filters: Filter[],
  paging: { page: number; pageSize: number },
  org?: Organization,
  config: AxiosRequestConfig = {},
  threads: boolean = true,
  /*hiddenProcessMetas: string[],
  state: ExecutionState,
  userId?: string,
  org?: Organization,*/
) => {
  if (!org) {
    /*const response = await ProcessExecutions.byUserIdAndState(
      userId!,
      state,
      undefined,
      {
        offset: paging.page * paging.pageSize,
        pageSize: paging.pageSize,
      },
      hiddenProcessMetas,
    );
    return {
      total_count: response.length,
      data: response,
    };*/
    return {
      total_count: 0,
      data: [],
    };
  }
  if (threads) {
    return ProcessExecutions.byOrganizationThreads(
      org.id,
      filters,
      {
        offset: paging.page * paging.pageSize,
        pageSize: paging.pageSize,
      },
      config,
    );
  }
  return ProcessExecutions.byOrganizationId(
    org.id,
    filters,
    {
      offset: paging.page * paging.pageSize,
      pageSize: paging.pageSize,
    },
    config,
  );
};

const DOWNLOAD_PAGE_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 1000,
};

const OrganizationProcessExecutions: FC<OrganizationProcessExecutionsProps> = ({
  currentOrganization,
}) => {
  const [testMode] = useAtom(testModeAtom);
  const [, setOnFilter] = useAtom(onProcessFilterAtom);
  const v2 = useFeature("v2", true);
  const [searchParams] = useSearchParams();
  const queryParamsRead = useRef(false);
  const navigate = useNavigate();
  const intl = useIntl();
  const showPhases = useFeature("account.phases");
  const { organizations } = useGlobalOrganizationContext();

  const { user } = useGlobalUserContext();

  const { handleRejectionWithError, success, error } = useAlert();

  const [inProgressPaginationModel, setInProgressPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [completedPaginationModel, setCompletedPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [selectedUser, setSelectedUser] = useState<User | null>();

  const [currentTabIndex, setCurrentTabIndex] = useState(
    searchParams.get("status") === "complete" ? 1 : 0,
  );
  const [quickFilter, setQuickFilter] = useState("all");

  const gridColumns = useMemo(() => {
    const gridColumns: GridColDef[] = [
      DEFAULT_GRID_COLUMNS.TITLE,
      DEFAULT_GRID_COLUMNS.ACCOUNT,
    ];
    // splices column in after phase to show process name if feature flag is used
    if (showPhases) {
      gridColumns.push({
        field: "phase",
        headerName: "Phase",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        headerClassName: "process-grid-header",
        renderCell: (params: GridRenderCellParams) => {
          return <ProcessPhaseChip {...params.row} />;
        },
      });
    }
    if (localStorage.getItem("runway.singleProcess")) {
      gridColumns.push({
        field: "rootProcess",
        headerName: "Process",
        sortable: true,
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 100,
        headerClassName: "process-grid-header",
        renderCell: (params: GridRenderCellParams) => {
          return <ProcessGridSelector {...params.row} />;
        },
      });
    }

    if (v2) {
      gridColumns.push(DEFAULT_GRID_COLUMNS.OWNER);
      gridColumns.push(DEFAULT_GRID_COLUMNS.PROCESS);
    } else {
      gridColumns.push(DEFAULT_GRID_COLUMNS.PROGRESS);
      // splices next task column in since a separate method is used
      gridColumns.push({
        field: "nextTask",
        headerName: "Next Task",
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        minWidth: 150,
        headerClassName: "process-grid-header",
        renderCell: RenderNextTask,
      });
      gridColumns.push(DEFAULT_GRID_COLUMNS.DUE_DATE);
      gridColumns.push(DEFAULT_GRID_COLUMNS.ASSIGNEE(user));
    }
    gridColumns.push(DEFAULT_GRID_COLUMNS.UPDATED);
    return gridColumns;
  }, [showPhases, user, v2]);

  // states to manage current process executions for organization
  const [inProgressProcessExecutions, setInProgressProcessExecutions] =
    useState<Array<ProcessExecution>>([]);
  const [completedProcessExecutions, setCompletedProcessExecutions] = useState<
    Array<ProcessExecution>
  >([]);
  const [totalInProgress, setTotalInProgress] = useState<number>(0);
  const [totalComplete, setTotalComplete] = useState<number>(0);
  const reloadPollTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const [gridFilters, setGridFilters] = useState<GridFilterModel>({
    items: [],
  });
  const [
    inProgressProcessExecutionsLoaded,
    setInProgressProcessExecutionsLoaded,
  ] = useState<boolean>(false);
  const [
    completedProcessExecutionsLoaded,
    setCompletedProcessExecutionsLoaded,
  ] = useState<boolean>(false);

  const [processExecutionSelectionModel, setProcessExecutionSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);

  const [chooseAccountDialogOpen, setChooseAccountDialogOpen] = useState(false);

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const [unread, setUnread] = useState(0);

  const executionMsg = intl.formatMessage(messages.execution.props);
  const executionsMsg = intl.formatMessage(messages.executions.props);
  const inProgressExecutionsMsg = intl.formatMessage(
    messages.inProgressExecutions.props,
  );
  const completedExecutionsMsg = intl.formatMessage(
    messages.completedExecutions.props,
  );
  // Update Navigation on filter or page change
  useEffect(() => {
    const filters = gridFilters.items
      .filter((filterItem) => {
        return filterItem.field === "assignee";
      })
      .map((filterItem) => {
        if (filterItem.field === "assignee") {
          if (filterItem.value === undefined) {
            return "assignee=unassigned";
          } else if (filterItem.value === user.id) {
            return "assignee=me";
          } else if (filterItem.value) {
            return "assignee=search&userID=" + filterItem.value;
          }
        }
      })
      .join("&");
    const complete = currentTabIndex === 1 ? "status=complete" : undefined;
    const paginationModel =
      currentTabIndex === 0
        ? inProgressPaginationModel
        : completedPaginationModel;
    const page =
      paginationModel.page > 0 ? `page=${paginationModel.page}` : undefined;
    const query = [complete, filters, page].filter((part) => !!part).join("&");
    navigate(query ? `?${query}` : "", {
      replace: true,
    });
  }, [
    completedPaginationModel,
    currentTabIndex,
    gridFilters,
    inProgressPaginationModel,
    navigate,
    user.id,
  ]);
  const addGridFilter = useCallback((filter: GridFilterItem) => {
    // Replace existing filter if any with new filter
    setGridFilters((filters) => {
      const filtersWithoutExistingFieldFilter = filters.items.filter(
        (f) => f.field !== filter.field,
      );
      const removeFilter =
        filter.operator === "equals" && filter.value === undefined;
      return {
        ...filters,
        items: removeFilter
          ? filtersWithoutExistingFieldFilter
          : [...filtersWithoutExistingFieldFilter, filter],
      };
    });
    // Reset pagination
    setInProgressPaginationModel((pageModel) => ({
      ...pageModel,
      page: 0,
    }));
    setCompletedPaginationModel((pageModel) => ({
      ...pageModel,
      page: 0,
    }));
  }, []);

  const handleQuickFilterChange = useCallback(
    (newFilter: string, existing: GridFilterModel, userId?: string | null) => {
      setQuickFilter(newFilter);
      const assigneeFilter: GridFilterItem = {
        field: "assignee",
        operator: "equals",
        value: undefined,
      };
      if (newFilter === "me") {
        assigneeFilter.value = user.id;
      } else if (newFilter === "unassigned") {
        assigneeFilter.operator = "isEmpty";
      } else if (newFilter === "search" && userId) {
        assigneeFilter.value = userId;
      }
      addGridFilter(assigneeFilter);
    },
    [addGridFilter, user.id],
  );

  const requestFilteredProcessExecutions = useCallback(
    (
      tabIndex: number,
      download = false,
    ): Promise<PagedResponse<ProcessExecution>> => {
      const config: AxiosRequestConfig = download ? { format: "xlsx" } : {};
      const hiddenProcessMetas =
        organizations.length > 0 ? environment.hiddenProcessMetaIds : undefined;
      const filters: Filter[] = hiddenProcessMetas
        ? [
            {
              field: "process_id",
              operator: "not in",
              value: hiddenProcessMetas.join(","),
            },
          ]
        : [];
      gridFilters.items.forEach((gridFilter) => {
        filters.push(toFilter(gridFilter));
        if (gridFilter.field === "assignee") {
          filters.push(
            toFilter({
              ...gridFilter,
              field: "owner",
            }),
          );
          filters.push(
            toFilter({
              ...gridFilter,
              field: "next_task_assignee",
            }),
          );
        }
      });

      if (tabIndex === 0) {
        filters.push({
          field: "state",
          operator: "=",
          value: ExecutionState.InProgress,
        });
        return requestProcessExecutions(
          filters,
          download ? DOWNLOAD_PAGE_MODEL : inProgressPaginationModel,
          currentOrganization,
          config,
          v2,
        ).then(
          (response) => {
            if (!download) {
              const loadedExecutions = response?.data ?? [];
              setInProgressProcessExecutions(loadedExecutions);
              setTotalInProgress(response.total_count);
              setInProgressProcessExecutionsLoaded(true);
            }
            return response;
          },
          handleRejectionWithError(`Failed to load ${executionsMsg}`),
        );
      } else {
        filters.push({
          field: "state",
          operator: "=",
          value: ExecutionState.Completed,
        });
        return requestProcessExecutions(
          filters,
          download ? DOWNLOAD_PAGE_MODEL : completedPaginationModel,
          currentOrganization,
          config,
          v2,
        ).then(
          (response) => {
            if (!download) {
              const loadedExecutions = response?.data ?? [];
              setCompletedProcessExecutions(loadedExecutions);
              setTotalComplete(response.total_count);
              setCompletedProcessExecutionsLoaded(true);
            }
            return response;
          },
          handleRejectionWithError(`Failed to load ${executionsMsg}`),
        );
      }
    },
    [
      organizations.length,
      gridFilters.items,
      inProgressPaginationModel,
      currentOrganization,
      v2,
      handleRejectionWithError,
      executionsMsg,
      completedPaginationModel,
    ],
  );

  const debouncedReload = useMemo(
    () =>
      debounce(
        (tabIndex) => {
          if (!user.id) {
            return;
          }
          requestFilteredProcessExecutions(tabIndex).then(() =>
            debouncedReload.cancel(),
          );
        },
        5000,
        { leading: true },
      ),
    [requestFilteredProcessExecutions, user.id],
  );

  const reload = useCallback(debouncedReload, [debouncedReload]);

  useEffect(() => {
    // default organization & user is empty
    // the result is that "fetchRoleIdsForUser" throws an Unauthorized exception
    // which leads to poor UX
    if (reloadPollTimer.current) {
      clearInterval(reloadPollTimer.current);
    }
    const timer = setInterval(() => reload(currentTabIndex), POLL_DURATION);
    reloadPollTimer.current = timer;
    reload(currentTabIndex);
    setOnFilter((process) => {
      // Filter process executions by process meta
      addGridFilter({
        field: "process_id",
        operator: "equals",
        value: process.id,
        process: process,
      } as unknown as GridFilterItem);
    });
    return () => {
      clearInterval(reloadPollTimer.current);
    };
  }, [addGridFilter, currentTabIndex, reload, setOnFilter]);

  useEffect(() => {
    if (queryParamsRead.current) {
      return;
    }
    const assignee = searchParams.get("assignee");
    const status = searchParams.get("status");
    const page = searchParams.get("page");
    const searchId = searchParams.get("userID");
    if (assignee) {
      handleQuickFilterChange(assignee, gridFilters, searchId);
    }
    if (searchId) {
      setSearchOpen(true);
    }
    const tabIndex = status === "complete" ? 1 : 0;
    if (status === "complete") {
      setCurrentTabIndex(tabIndex);
      if (reloadPollTimer.current) {
        clearInterval(reloadPollTimer.current);
      }
    }
    if (page) {
      const pageIndex = parseInt(page);
      if (tabIndex === 0) {
        setInProgressPaginationModel((pageModel) => ({
          ...pageModel,
          page: pageIndex,
        }));
      } else {
        setCompletedPaginationModel((pageModel) => ({
          ...pageModel,
          page: pageIndex,
        }));
      }
    }
    queryParamsRead.current = true;
  }, [gridFilters, handleQuickFilterChange, reloadPollTimer, searchParams]);

  useEffect(() => {
    if (!currentOrganization) {
      return;
    }

    ProcessExecutionAPI.getUnreadCount(currentOrganization.id).then((count) => {
      setUnread(count);
    }, handleRejectionWithError("Failed to get unread count"));
  }, [
    organizations.length,
    currentOrganization,
    user.id,
    inProgressProcessExecutions,
    handleRejectionWithError,
  ]);

  const assignMemberBtn = (
    <ToolbarButton
      dataCy="set-execution-account-btn"
      key="assignMemberBtn"
      startIcon={<AssignmentIndOutlined />}
      onClick={() => setAssignDialogOpen(true)}
      textContent="Assign"
    />
  );

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-execution-btn"
      key="deleteBtn"
      startIcon={<DeleteOutlined />}
      onClick={() => setDeleteDialogOpen(true)}
      textContent="Delete"
    />
  );

  const setAccountBtn = (
    <ToolbarButton
      dataCy="set-execution-account-btn"
      key="setAccountBtn"
      startIcon={<AccountCircleIcon />}
      onClick={() => setChooseAccountDialogOpen(true)}
      textContent="Set Account"
    />
  );

  const completeBtn = (
    <ToolbarButton
      dataCy="complete-execution-btn"
      key="completeBtn"
      startIcon={<CheckCircleOutline />}
      onClick={() => setCompleteDialogOpen(true)}
      textContent="Complete"
    />
  );

  const [searchOpen, setSearchOpen] = useState(false);

  const processFilter = gridFilters.items.find(
    (item) => item.field === "process_id",
  ) as (GridFilterItem & { process: Process }) | undefined;

  const filterBtnGrp = (
    <Box
      key="filterBtnGrp"
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "12px",
        alignItems: "center",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={quickFilter}
        size="small"
        exclusive
        onChange={(evt, value) => {
          if (value === null) {
            value = "all";
          }
          if (value != "search") {
            setSearchOpen(false);
            handleQuickFilterChange(value, gridFilters);
          } else {
            handleQuickFilterChange(value, gridFilters, selectedUser?.id);
          }
        }}
        aria-label="Filter"
      >
        <ToggleButton size="small" value="all">
          All
        </ToggleButton>
        {currentTabIndex != 1 && (
          <ToggleButton size="small" value="unassigned">
            Unassigned
          </ToggleButton>
        )}
        <ToggleButton size="small" value="me">
          My {executionsMsg}
        </ToggleButton>
        <ToggleButton
          size="small"
          value="search"
          sx={{ mr: 1 }}
          onClick={() => setSearchOpen(!searchOpen)}
        >
          <GridSearchIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {processFilter && (
        <ProcessChip
          name={processFilter.process?.name ?? ""}
          icon={processFilter.process?.icon ?? ""}
          onDelete={() => {
            setGridFilters((filters) => ({
              ...filters,
              items: filters.items.filter(
                (filterItem) => filterItem.field !== "process_id",
              ),
            }));
          }}
        />
      )}
      <Box sx={{ minWidth: "300px" }} hidden={!searchOpen}>
        <UserSelection
          organizationId={currentOrganization?.id}
          AutoCompleteProps={{
            size: "small",
            renderInput: (params) => (
              <TextField {...params} label={"Search Assignees"} />
            ),
            multiple: false,
            value: () => {
              return selectedUser ?? null;
            },
            onChange: (_, user) => {
              setSelectedUser(user);
              if (user) {
                handleQuickFilterChange("search", gridFilters, user.id);
              } else {
                handleQuickFilterChange("", gridFilters);
              }
            },
          }}
        />
      </Box>
    </Box>
  );

  const inProgressCountMsg = useMemo(() => {
    const testExecutions = inProgressProcessExecutions.filter((e) => {
      if (e.execution_type === ExecutionType.Test) return e;
    });

    const nonTestCount = totalInProgress - testExecutions.length;

    if (testMode) {
      return `${nonTestCount} ${inProgressExecutionsMsg} and ${
        testExecutions.length
      } ${testExecutions.length == 1 ? " Test" : " Tests"}`;
    }
    return `${totalInProgress} ${inProgressExecutionsMsg}`;
  }, [
    inProgressExecutionsMsg,
    inProgressProcessExecutions,
    testMode,
    totalInProgress,
  ]);

  const completedCountMsg = useMemo(() => {
    const testExecutions = completedProcessExecutions.filter((e) => {
      if (e.execution_type === ExecutionType.Test) return e;
    });

    const nonTestCount = totalComplete - testExecutions.length;

    if (testMode) {
      return `${nonTestCount} ${completedExecutionsMsg} and ${
        testExecutions.length
      } ${testExecutions.length == 1 ? " Test" : " Tests"}`;
    }
    return `${totalComplete} ${completedExecutionsMsg}`;
  }, [
    completedExecutionsMsg,
    completedProcessExecutions,
    testMode,
    totalComplete,
  ]);

  const assignMember = (id: string) => {
    Promise.all(
      processExecutionSelectionModel.map((peId) => {
        return ProcessExecutions.assignProcessExecution(peId as string, id);
      }),
    )
      .then(() => {
        reload(currentTabIndex);
        success("Assignment successful");
      })
      .catch(() => {
        error("Some assignments failed");
      });
  };
  const commonProps: Partial<ProcessExecutionDataGridProps> = {
    paginationMode: "server",
    rowSelectionModel: processExecutionSelectionModel,
    slots: { toolbar: DatagridToolbar },
    getRowClassName: (params) => {
      return params.row.execution_type == ExecutionType.Test
        ? TEST_ROW_CLASS_NAME
        : "";
    },
    /* FILTERING */
    filterModel: gridFilters,
    filterMode: "server",
    onFilterModelChange: (filters) => {
      setGridFilters(filters);
    },
    columnVisibilityModel: {
      id: false,
    },
    /* EDITING */
    onCellEditStart: () => {
      // prevent refresh
      clearInterval(reloadPollTimer.current);
    },
    onCellEditStop: () => {
      const timer = setInterval(() => reload(currentTabIndex), POLL_DURATION);
      reloadPollTimer.current = timer;
    },
    onRowSelectionModelChange: (newSelectionModel) => {
      setProcessExecutionSelectionModel(newSelectionModel);
      setNumRowsSelected(newSelectionModel.length);
    },
  };
  const moreMenuBtn = (
    <DatagridToolbarMenuButton
      items={[
        {
          label: "Export",
          icon: CloudDownload,
          onClick: () => {
            const dateString = new Date().toISOString().slice(0, 10);
            requestFilteredProcessExecutions(currentTabIndex, true).then(
              (blob) =>
                downloadApiFile(
                  blob as unknown as Blob,
                  `${
                    currentOrganization?.key ?? currentOrganization?.name
                  }-${executionsMsg.toLocaleLowerCase()}-${dateString}.xlsx`,
                ),
              handleRejectionWithError(
                `Failed to download ${executionsMsg} file`,
              ),
            );
          },
        },
        {
          component: ({ onClick }) => (
            <TestModeToggle
              objectTitle={executionsMsg}
              variant="menuItem"
              onUpdate={() => {
                reload(currentTabIndex);
                onClick();
              }}
            />
          ),
        },
      ]}
    />
  );

  return (
    <>
      <PageHeader title={`${executionsMsg}${unread ? ` (${unread})` : ""}`}>
        <Typography variant="h1">{executionsMsg}</Typography>
      </PageHeader>

      <Grid
        item
        container
        flexDirection="column"
        flex={1}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Tabs
          value={currentTabIndex}
          onChange={(event, newIndex: number) => {
            // Stop existing poll loop
            if (reloadPollTimer.current) {
              clearInterval(reloadPollTimer.current);
            }
            setCurrentTabIndex(newIndex);
          }}
          sx={{
            height: "2em",
            minHeight: "2em",
            "& .MuiButtonBase-root": {
              height: "2rem",
              minHeight: "2rem",
            },
          }}
        >
          <Tab
            data-cy="in-progress-executions-tab"
            label={inProgressExecutionsMsg}
            icon={<ListAlt />}
            iconPosition="start"
          />

          <Tab
            data-cy="completed-executions-tab"
            label={completedExecutionsMsg}
            icon={<CheckCircleOutline />}
            iconPosition="start"
          />
        </Tabs>
        {((currentTabIndex === 0 && !inProgressProcessExecutionsLoaded) ||
          (currentTabIndex === 1 && !completedProcessExecutionsLoaded)) && (
          <CircularProgress data-cy="circular-progress" />
        )}
        {inProgressProcessExecutionsLoaded && currentTabIndex == 0 && (
          <ProcessExecutionDataGrid
            slotProps={{
              toolbar: {
                countMsg: inProgressCountMsg,
                numSelected: numRowsSelected,
                selectedBtns: [
                  completeBtn,
                  assignMemberBtn,
                  setAccountBtn,
                  deleteBtn,
                ],
                defaultBtns: [filterBtnGrp, moreMenuBtn],
                testModeToggle: <TestModeStatus objectTitle={executionsMsg} />,
              },
            }}
            columns={gridColumns}
            rows={inProgressProcessExecutions}
            paginationModel={inProgressPaginationModel}
            rowCount={totalInProgress}
            onPaginationModelChange={(paginationModel) => {
              setInProgressPaginationModel(paginationModel);
            }}
            {...commonProps}
          />
        )}
        {completedProcessExecutionsLoaded && currentTabIndex == 1 && (
          <ProcessExecutionDataGrid
            slotProps={{
              toolbar: {
                countMsg: completedCountMsg,
                numSelected: numRowsSelected,
                selectedBtns: [setAccountBtn, deleteBtn],
                defaultBtns: [filterBtnGrp, moreMenuBtn],
                testModeToggle: <TestModeStatus objectTitle={executionsMsg} />,
              },
            }}
            columns={gridColumns}
            rows={completedProcessExecutions}
            paginationModel={completedPaginationModel}
            rowCount={totalComplete}
            onPaginationModelChange={(paginationModel) => {
              setCompletedPaginationModel(paginationModel);
            }}
            {...commonProps}
          />
        )}
      </Grid>
      {completeDialogOpen && (
        <ConfirmationModal
          confirmationData={{
            title:
              processExecutionSelectionModel.length > 1
                ? `Complete ${executionsMsg}`
                : `Complete ${executionMsg}`,
            body:
              processExecutionSelectionModel.length === 1
                ? `Are you sure you want to complete this ${executionMsg}?`
                : `Are you sure you want to complete these ${processExecutionSelectionModel.length} ${executionsMsg}?`,
            cancelLabelText: "Cancel",
            continueLabelText: "Mark Complete",
          }}
          openModal={completeDialogOpen}
          handleCloseModal={() => setCompleteDialogOpen(false)}
          executeModal={() => {
            processExecutionSelectionModel.map((peId) =>
              ProcessExecutions.complete(peId as string).then(
                () => {
                  reload(currentTabIndex);
                  success(
                    "Successfully completed " +
                      executionsMsg.toLocaleLowerCase(),
                  );
                },
                handleRejectionWithError(
                  "Failed to complete " + executionsMsg.toLocaleLowerCase(),
                ),
              ),
            );
          }}
        />
      )}
      {chooseAccountDialogOpen && currentOrganization && (
        <ChooseAccountDialog
          organization={currentOrganization}
          open={chooseAccountDialogOpen}
          onSelect={async (account) => {
            const promises = Promise.all(
              processExecutionSelectionModel.map((peId) =>
                ProcessExecutions.update(peId as string, {
                  subject_id: account.id,
                }),
              ),
            ).then(
              () => {
                reload(currentTabIndex);
                success(
                  `Successfully changed account to ${account.account_name} for ` +
                    executionsMsg.toLocaleLowerCase(),
                );
              },
              handleRejectionWithError(
                "Failed to assign account to " +
                  executionsMsg.toLocaleLowerCase(),
              ),
            );
            await promises;
            // TODO - set account on selected process executions
          }}
          onClose={() => setChooseAccountDialogOpen(false)}
        />
      )}
      {deleteDialogOpen && (
        <DeleteDialog
          title={
            processExecutionSelectionModel.length > 1
              ? `Delete ${executionsMsg}`
              : `Delete ${executionMsg}`
          }
          question={`Are you sure you want to delete ${
            processExecutionSelectionModel.length > 1
              ? `these ${executionsMsg.toLocaleLowerCase()}`
              : `this ${executionMsg.toLocaleLowerCase()}`
          }? The data will not be recoverable and this procedure cannot be undone.`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={() => {
            return Promise.all(
              processExecutionSelectionModel.map((peId) =>
                ProcessExecutions.deleteProcessExecution(peId as string),
              ),
            ).then(
              () => {
                reload(currentTabIndex);
                success(
                  "Successfully deleted " + executionsMsg.toLocaleLowerCase(),
                );
              },
              handleRejectionWithError(
                "Failed to delete all " + executionsMsg.toLocaleLowerCase(),
              ),
            );
          }}
        />
      )}
      {assignDialogOpen && currentOrganization && (
        <AssignDialog
          open={assignDialogOpen}
          orgId={currentOrganization.id}
          onClose={() => setAssignDialogOpen(false)}
          onConfirm={(userId) => assignMember(userId)}
        />
      )}
    </>
  );
};

export default OrganizationProcessExecutions;
