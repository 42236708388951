import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab, Drawer, Box, Tooltip, Typography } from "@mui/material";
import { SideNavigationProps } from "../../../types";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";

import PATHS from "../../navigation/_paths";
import useFeature from "../../../hooks/useFeature";
import { Organization } from "../../../model";
import ManageOrganizationMenu from "../../../features/organization/components/ManageOrganizationMenu";

const renderOrganizationTile = (
  organization: Organization,
  onClick: (organization: Organization) => void,
) => {
  const orgInitials = organization.name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  return (
    <Tab
      data-cy={"org-tab"}
      key={`${organization.key}-space`}
      icon={
        <Tooltip
          data-cy={"org-tab-tooltip"}
          title={organization.name}
          placement="right"
          arrow
        >
          {organization.theme?.shortLogoUrl ? (
            <Box
              style={{
                backgroundImage: `url('${organization.theme?.shortLogoUrl}')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundClip: "border-box",
                backgroundSize: "38px 38px",
                backgroundColor: "#fff",
                height: 38,
                width: 38,
                borderRadius: 6,
                border: "1px solid #cccccc",
              }}
            />
          ) : (
            <Box
              style={{
                backgroundColor: "#fff",
                height: 38,
                width: 38,
                borderRadius: 6,
                border: "1px solid #cccccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>{orgInitials}</Typography>
            </Box>
          )}
        </Tooltip>
      }
      value={organization.key}
      onClick={() => onClick(organization)}
      sx={{
        "&.Mui-selected": {
          backgroundColor: "primary.main",
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
          "&::before": {
            content: "''",
            width: "12px",
            height: "12px",
            position: "absolute",
            right: 0,
            top: -12,
            borderBottomRightRadius: "6px",
            boxShadow: (theme) => `6px 0px 0 0 ${theme.palette.primary.main}`,
          },
          "&::after": {
            content: "''",
            width: "12px",
            height: "12px",
            position: "absolute",
            right: 0,
            bottom: -12,
            borderTopRightRadius: "6px",
            boxShadow: (theme) => `6px 0px 0 0 ${theme.palette.primary.main}`,
          },
        },
        py: "4px",
        paddingRight: "8px",
        paddingLeft: "4px",
        marginLeft: "4px",
        overflow: "visible",
      }}
    ></Tab>
  );
};

const SideNavigation = ({ offset = 64 }: SideNavigationProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const canCreateOrganization = useFeature("organization.create");
  const {
    organization,
    organizations,
    setOrganization,
    accountListIsMemberOf,
  } = useGlobalOrganizationContext();
  const organizationSelected = !!organization;
  return (
    <Drawer
      anchor="left"
      open={true}
      variant="persistent"
      elevation={1}
      sx={{ displayPrint: "none" }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          whiteSpace: "nowrap",
          backgroundColor: "background.paper",
          paddingTop: `${offset + 12}px`,
          height: "100%",
          borderRight: organizationSelected ? "4px solid" : undefined,
          borderRightColor: organizationSelected ? "primary.main" : undefined,
        }}
      >
        <Tabs
          orientation="vertical"
          value={organization?.key ?? organizations.length}
          centered={true}
          aria-label="global navigation"
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            display: "flex",
            height: "100%",
            "& 	.MuiTabs-fixed": {
              height: "100%",
              display: "flex",
            },
          }}
        >
          {organizations.map((organization) => {
            return renderOrganizationTile(organization, (organization) => {
              setOrganization(organization);
              if (
                !pathname.startsWith("/user/organization/") ||
                pathname.search(
                  /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[4][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/,
                ) >= 0
              ) {
                navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
              }
            });
          })}

          {accountListIsMemberOf.map((account) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const organization = account.organization!;
            return renderOrganizationTile(organization, () => {
              setOrganization(organization);
              navigate(PATHS.ACCOUNT_PORTAL.linkTo(account.id));
            });
          })}
          <Tab sx={{ flex: 1 }} disabled />
          {canCreateOrganization && <ManageOrganizationMenu />}
        </Tabs>
      </Box>
    </Drawer>
  );
};

export default SideNavigation;
