import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  FormElementType,
  TaskExecutionComponentProps,
  TaskExecutionState,
  TaskForm,
  TaskFormExecution,
  TaskViews,
} from "../../../types";
import PATHS from "../../../../../components/navigation/_paths";
import { DEFAULT_RENDER } from "../TaskBody";
import FormExecutionComponent from "./FormExecutionComponent";

const MAX_ELEMENTS_FOR_INLINE_TASK_DISPLAY = 6;

const FormExecutionComponentWrapper = ({
  children = DEFAULT_RENDER,
  ...props
}: TaskExecutionComponentProps<TaskForm, TaskFormExecution>) => {
  const navigate = useNavigate();

  const propsTask = props.task as TaskForm;
  if (!propsTask.data?.form) {
    console.warn(`Received empty form for task with id ${propsTask.id}`);
    return <Box></Box>;
  }

  const buttonText =
    props.taskExecution?.state == TaskExecutionState.Completed
      ? "View submission"
      : "Open form";
  const form = propsTask.data?.form;
  if (
    (form.elements.length > MAX_ELEMENTS_FOR_INLINE_TASK_DISPLAY ||
      form.elements.some(
        (element) => element.type === FormElementType.NestedForm,
      )) &&
    props.view === TaskViews.CONDENSED
  ) {
    return children({
      // display button to navigate to "long" TaskForm page (
      content: (
        <Box component="section">
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                PATHS.EXECUTION_TASK_FORM.linkTo(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  (props.processExecution?.process_id ??
                    props.task.process_id)!,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  (props.processExecution?.id ??
                    props.taskExecution?.process_execution_id)!,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  props.task.id!,
                ),
                {
                  state: { isDisabled: props.isDisabled },
                },
              )
            }
            sx={{ textTransform: "unset" }}
          >
            {buttonText}
          </Button>
        </Box>
      ),
    });
  }

  return (
    <FormExecutionComponent
      {...props}
      isShortTaskForm={
        props.isShortTaskForm == undefined ? true : props.isShortTaskForm
      }
      showPrint={true}
    >
      {children}
    </FormExecutionComponent>
  );
};

export default FormExecutionComponentWrapper;
