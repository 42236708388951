import { FC, useCallback, useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { TaskExecutionState, TextFormElement } from "../../../../types";
import { formatTextFormElementData } from "../../../../../../lib/format";
import ReactQuillEditor from "../../../../../../components/core/ReactQuill/ReactQuillEditor";
import ReactQuillViewerComplete from "../../../../../../components/core/ReactQuill/ReactQuillViewer";
import { useAtom } from "jotai";
import { eventsAtom } from "../../../../hooks/useEvents";
import jp from "jsonpath";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

export enum TextFormElementType {
  TextArea,
  RichText,
  TextField,
}

type TaskTextFormType = {
  formId?: string;
  disabled: boolean;
  onValueChange: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
} & TextFormElement;

const TaskTextFormComponent: FC<TaskTextFormType> = ({
  execution,
  onValueChange,
  taskExecutionState,
  ...props
}) => {
  const [events] = useAtom(eventsAtom);
  const [value, setValue] = useState<string>(execution?.value || "");

  const submitValue = useCallback(
    (value: string) => {
      setValue(value);
      onValueChange(value);
    },
    [onValueChange],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      submitValue(value);
    },
    [submitValue],
  );

  useEffect(() => {
    if (execution?.value) {
      submitValue(
        formatTextFormElementData(execution?.value, execution?.dataType) ?? "",
      );
    }
  }, [submitValue, execution?.value, execution?.dataType]);
  useEffect(() => {
    //if (props.link) {
    const listener = (object: Record<string, unknown>) => {
      if (props.link) {
        const value = jp.value(object, props.link ?? "");
        setValue(value);
        submitValue(value);
      }
    };
    events.on(`object`, listener);
    return () => {
      events.off(`object`, listener);
    };
    //}
  }, [events, props.link, props.name, submitValue]);

  const label = (
    <Typography variant="body1" sx={{ display: "inline" }}>
      {props.label}
    </Typography>
  );

  const type = props.textArea
    ? TextFormElementType.TextArea
    : props.richText
      ? TextFormElementType.RichText
      : TextFormElementType.TextField;

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item>
        {type === TextFormElementType.TextArea && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <TextField
              multiline
              rows={3}
              name={props.name}
              fullWidth
              variant="outlined"
              type="text"
              disabled
              InputProps={{ readOnly: props.disabled }}
              value={value}
              inputProps={{ style: { fontSize: "0.875rem" } }}
              sx={{ bgcolor: "common.white" }}
            />
          </Grid>
        )}
        {type === TextFormElementType.TextField && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <Typography>{value}</Typography>
          </Grid>
        )}
        {type === TextFormElementType.RichText && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <ReactQuillViewerComplete value={value} variant="regular" />
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Grid item>
      <FormGroup id={props.formId}>
        {type === TextFormElementType.TextArea && (
          <TextField
            multiline
            label={label}
            rows={5}
            name={props.name}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="text"
            InputProps={{ readOnly: props.disabled }}
            value={value}
            helperText={props.description}
            inputProps={{ style: { fontSize: "0.875rem" } }}
            sx={{ bgcolor: "common.white" }}
          />
        )}
        {type === TextFormElementType.TextField && (
          <TextField
            fullWidth
            label={label}
            name={props.name}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="text"
            InputProps={{ readOnly: props.disabled }}
            value={value}
            helperText={props.description}
            inputProps={{ style: { fontSize: "0.875rem" } }}
            sx={{ bgcolor: "common.white" }}
          />
        )}
        {type === TextFormElementType.RichText && (
          <FormControl>
            <FormLabel id={`${props.id}-label`} required={props.required}>
              {label}
            </FormLabel>
            <FormElementTaskDescription>
              {props.description}
            </FormElementTaskDescription>
            <ReactQuillEditor
              aria-labelledby={`${props.id}-label`}
              disabled={props.disabled}
              onChange={submitValue}
              value={value}
              id={props.id}
            />
          </FormControl>
        )}
      </FormGroup>
    </Grid>
  );
};

export default TaskTextFormComponent;
