import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import { TextFormElement, TextFormElementExecution } from "../../../types";
import { FormElementChangeType } from "../FormBuilder";
import { TextFormElementType } from "../../task-types/form/task-form-elements/TaskTextFormComponent";

export const TextSideSectionDisplay = ({
  element,
  onChange,
}: {
  element: TextFormElement;
  executionData?: TextFormElementExecution;
  onChange: (props: FormElementChangeType) => void;
}) => {
  const type = element.textArea
    ? TextFormElementType.TextArea
    : element.richText
      ? TextFormElementType.RichText
      : TextFormElementType.TextField;
  return (
    <FormControl>
      <FormLabel id="form-element-text-type-label">Type</FormLabel>
      <RadioGroup
        aria-labelledby="form-element-text-type-label"
        name="form-element-text-type"
        onChange={(evt, value) => {
          const v = parseInt(value);
          const textArea = v === TextFormElementType.TextArea;
          const richText = v === TextFormElementType.RichText;
          onChange({
            changeType: "update",
            newElement: {
              ...element,
              textArea,
              richText,
            },
          });
        }}
        value={type}
      >
        <FormControlLabel
          value={TextFormElementType.TextField}
          label="Text Field"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormElementType.TextArea}
          label="Text Area"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormElementType.RichText}
          label="Rich Text"
          control={<Radio />}
        />
      </RadioGroup>
    </FormControl>
  );
};
