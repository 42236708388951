import { useState, useCallback, useEffect } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import debounce from "lodash/debounce";

export type DebouncedTextFieldProps = {
  onValueChange: (value: string) => Promise<boolean>;
} & TextFieldProps;

const DebouncedTextField = ({
  value,
  onValueChange,
  ...props
}: DebouncedTextFieldProps) => {
  const [v, setV] = useState(value);
  const [isValid, setIsValid] = useState(true);

  // Function to validate input
  const validateInput = useCallback(
    debounce(async (inputValue) => {
      try {
        const response = await onValueChange(inputValue);
        setIsValid(response);
      } catch (error) {
        console.error("Error validating input:", error);
      }
    }, 200),
    [],
  );

  // Update debounced validation function when value changes
  useEffect(() => {
    if (v) validateInput(v);
  }, [v, validateInput]);

  useEffect(() => {
    setV(value);
  }, [value]);

  return (
    <TextField
      error={!isValid}
      helperText={!isValid ? "Value is not valid" : ""}
      label="Input"
      value={v}
      onChange={(event) => {
        setV(event.target.value);
      }}
      {...props}
    />
  );
};

export default DebouncedTextField;
