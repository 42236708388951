declare module "@mui/material/styles" {
  interface TypeBackground {
    drawer?: string;
    menu?: string;
  }
  interface PaletteOptions {
    progress?: {
      progress?: string;
      remaining?: string;
      complete?: string;
    };
    testRow?: {
      default?: string;
      hover?: string;
      selected?: string;
      selectedHover?: string;
    };
  }
  interface Palette {
    progress: {
      progress: string;
      remaining: string;
      complete: string;
    };
    testRow: {
      default: string;
      hover: string;
      selected: string;
      selectedHover: string;
    };
  }
}

import { deepmerge } from "@mui/utils";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { CssBaseline, ThemeProvider, responsiveFontSizes } from "@mui/material";
import environment from "../../util/environment";
import darkTheme from "./darkTheme";
import baseTheme from "./baseTheme";

const RunwayThemeProvider = (props: React.PropsWithChildren<unknown>) => {
  const ff_DarkMode = localStorage.getItem("runway.darkMode");
  const ff_CustomeTheme = localStorage.getItem("runway.theme");
  const defaultTheme = ff_DarkMode
    ? deepmerge(baseTheme, darkTheme)
    : baseTheme;
  return (
    <ThemeProvider theme={defaultTheme}>
      <ThemeProvider
        theme={(theme) => {
          let themeOverride: Partial<ThemeOptions> = theme;
          if (environment.theme) {
            themeOverride = deepmerge(themeOverride, environment.theme);
          }
          if (ff_CustomeTheme) {
            themeOverride = deepmerge(
              themeOverride,
              JSON.parse(ff_CustomeTheme),
            );
          }
          const combinedTheme = createTheme(theme, themeOverride);
          return responsiveFontSizes(combinedTheme);
        }}
      >
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ThemeProvider>
  );
};

export default RunwayThemeProvider;
