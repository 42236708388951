import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import PATHS from "../../../../components/navigation/_paths";
import { FilterAlt } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useAtom } from "jotai";
import { onProcessFilterAtom } from "../../state";
import ProcessChip from "../ProcessChip";

const ProcessExecutionCell = (params: GridRenderCellParams) => {
  const [actionsRightPosition, setActionsRightPosition] = useState(0);
  const [onProcessFilter] = useAtom(onProcessFilterAtom);
  const cellRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  if (!params.row.process_name) {
    return <></>;
  }
  return (
    <Box
      width="100%"
      onMouseOver={() => {
        if (cellRef.current) {
          setActionsRightPosition(
            cellRef.current.offsetLeft + cellRef.current.offsetWidth - 40,
          );
        }
      }}
      onMouseLeave={() => setActionsRightPosition(0)}
      ref={cellRef}
    >
      <ProcessChip
        icon={params.row.process_icon}
        name={params.row.process_name}
        onClick={() =>
          navigate(PATHS.EXECUTION.linkTo(params.row.process_id, params.row.id))
        }
      />
      <IconButton
        size="small"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onProcessFilter &&
            onProcessFilter({
              id: params.row.process_id,
              name: params.row.process_name,
              icon: params.row.process_icon,
            });
        }}
        sx={{
          position: "absolute",
          ml: 1,
          ...(actionsRightPosition > 0
            ? {
                left: actionsRightPosition,
              }
            : { display: "none" }),
        }}
      >
        <FilterAlt />
      </IconButton>
    </Box>
  );
};
export default ProcessExecutionCell;
