import HeroContainer from "../../../../../components/elements/HeroContainer";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { Square } from "@mui/icons-material";
import { DashboardElementMap } from "../../../../../model/Dashboard";
import { useMemo } from "react";
import ClientLocationMap from "./ClientLocationMap";

type ClientLocationProps = {
  element: DashboardElementMap;
};

export type MapData = { label: string; color: string; value: number }[];

const ClientLocation = ({ element }: ClientLocationProps) => {
  const theme = useTheme();
  const metricData = useMemo<MapData>(() => {
    const data: MapData = [
      { label: "City of Marquette", color: "#594E95", value: 0 },
      { label: "Marquette County", color: "#FFD151", value: 0 },
      { label: "Upper Peninsula", color: "#467D93", value: 0 },
      { label: "Lower Peninsula", color: "#269C88", value: 0 },
      { label: "Outside of Michigan", color: "#B3DACC", value: 0 },
    ];
    switch (element.config.dataSource) {
      case "fieldDefinitionValue":
        (element.data ?? []).map(({ label, count }) => {
          const i = data.findIndex((e) => e.label == label);
          if (i < 0) {
            data.push({
              label,
              value: count,
              color: theme.palette.primary.main,
            });
          } else {
            data[i] = { ...data[i], value: count };
          }
        });
    }
    return data;
  }, [element.config.dataSource, element.data, theme.palette.primary.main]);

  return (
    <HeroContainer displayTopographic>
      <Grid container justifyContent={"center"}>
        <ClientLocationMap mapData={metricData} />
      </Grid>
      <Paper
        elevation={4}
        sx={{
          position: "absolute",
          bottom: 15,
        }}
      >
        <List>
          {metricData.map((option) => (
            <ListItem key={option.label}>
              <ListItemIcon>
                <Square sx={{ color: option.color }} />
              </ListItemIcon>
              <Typography display={"inline"}>{option.label}</Typography>
              {metricData && (
                <Typography
                  display={"inline"}
                  fontWeight={"bold"}
                  sx={{ pl: 0.5 }}
                >
                  ({option.value})
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
    </HeroContainer>
  );
};

export default ClientLocation;
