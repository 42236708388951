import { Box, Container, Skeleton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useContext, useMemo } from "react";
import { DashboardElementNumberCard } from "../../../../model/Dashboard";
import HeroContainer from "../../../../components/elements/HeroContainer";
import DashboardContext from "../../hooks/DashboardContext";

type NumberCardProps = {
  element: DashboardElementNumberCard;
};

export type NumberCardData = {
  label: string;
  cumulative: number;
  inRange?: number;
};

export const NumberCard: FC<NumberCardProps> = ({ element }) => {
  const { currentDashboard, dateRange } = useContext(DashboardContext);
  const metricData = useMemo<NumberCardData | undefined>(
    () => element.data,
    [element.data],
  );

  const InRangeLabel = useMemo(() => {
    if (currentDashboard?.config?.hideDatePicker) {
      return "Year to Date";
    }
    return "Time Range";
  }, [currentDashboard?.config?.hideDatePicker]);

  if (!metricData) return <NumberCardSkeleton />;

  return (
    <HeroContainer
      displayTopographic
      sx={{
        p: 0,
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        minWidth: "16.87em",
      }}
    >
      <Box
        sx={{
          bgcolor: element.color ?? "primary.main",
          width: "1rem",
        }}
      />
      <Box
        sx={{
          py: 1,
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: 1,
        }}
      >
        <Typography variant="body2" fontWeight="bold">
          {metricData.label}
        </Typography>

        {dateRange && dateRange.start && dateRange.end ? (
          <Container
            disableGutters
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Container
              disableGutters
              sx={{
                borderRightWidth: 1.5,
                borderRightStyle: "solid",
                borderRightColor: grey[400],
              }}
            >
              <Typography variant="body2">Cumulative</Typography>
              <Typography
                variant="h2"
                component="p"
                sx={{ fontSize: "1.5rem !important" }}
              >
                {metricData.cumulative}
              </Typography>
            </Container>
            <Container disableGutters sx={{ ml: 2 }}>
              <Typography variant="body2">{InRangeLabel}</Typography>
              <Typography
                variant="h2"
                component="p"
                sx={{ fontSize: "1.5rem !important" }}
              >
                {metricData.inRange ?? 0}
              </Typography>
            </Container>
          </Container>
        ) : (
          <Typography variant="h2" sx={{ my: 1 }}>
            {metricData.cumulative}
          </Typography>
        )}
      </Box>
    </HeroContainer>
  );
};

export const NumberCardSkeleton = () => (
  <HeroContainer
    displayTopographic
    sx={{
      p: 0,
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      minWidth: "15em",
    }}
  >
    <Box
      sx={{
        bgcolor: "primary.main",
        width: "1rem",
      }}
    />
    <Box
      sx={{
        py: 1,
        px: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: 1,
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: "1em", width: 0.6 }} />
      <Container disableGutters sx={{ display: "flex", flexDirection: "row" }}>
        <Container disableGutters>
          <Skeleton variant="text" sx={{ fontSize: "0.8em", width: "6em" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.1em", width: "2em" }} />
        </Container>
        <Container disableGutters>
          <Skeleton variant="text" sx={{ fontSize: "0.8em", width: "6em" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.1em", width: "2em" }} />
        </Container>
      </Container>
    </Box>
  </HeroContainer>
);
