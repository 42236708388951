import { Chip, SxProps } from "@mui/material";
import DynamicIcon from "../../../components/core/DynamicIcon";
import { Cancel } from "@mui/icons-material";

const ProcessChip = ({
  icon,
  name,
  onClick,
  onDelete,
  sx,
}: {
  icon?: string;
  name: string;
  onClick?: () => void;
  onDelete?: () => void;
  sx?: SxProps;
}) => {
  return (
    <Chip
      icon={icon ? <DynamicIcon name={icon} /> : undefined}
      component="a"
      deleteIcon={onDelete ? <Cancel /> : undefined}
      onClick={onClick}
      onDelete={onDelete}
      label={name}
      sx={{
        color: (theme) => theme.palette.text.secondary,
        paddingLeft: "4px",
        ...sx,
      }}
    />
  );
};
export default ProcessChip;
