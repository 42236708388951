import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Users from "../../../api/users";
import { Role, User } from "../../../model";
import {
  GlobalPageContext,
  Display,
} from "../../../hooks/useGlobalPageContext";

import Roles from "edo-platform-frontend/src/api/roles";
import { GlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { DefaultAlertSnackbar, useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import DashboardLayout from "./DashboardLayout";
import { CommonRole, SideNavigationProps } from "../../../types";
import { GlobalRoleContext } from "../../../hooks/useGlobalRoleContext";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { GlobalOrganizationProvider } from "../../../features/organization";
import { environment } from "../../../util";
import { AccountTypeAPI } from "../../../api";

const DashboardContext = ({
  children,
}: React.PropsWithChildren<{ orgKey?: string }>) => {
  const { handleRejectionWithWarning } = useAlert();
  const [user, setUser] = useState<User>(new User());
  //fetch user info & organization
  useEffect(() => {
    Users.get(currentUserId()).then((user) => {
      setUser(user);
    }, handleRejectionWithWarning("Could not get current user"));
  }, [handleRejectionWithWarning]);
  return (
    <DefaultAlertSnackbar>
      <GlobalUserContext.Provider value={{ user, setUser }}>
        <GlobalOrganizationProvider orgKey={environment.organization?.key}>
          {children}
        </GlobalOrganizationProvider>
      </GlobalUserContext.Provider>
    </DefaultAlertSnackbar>
  );
};

const DashboardInner: FC<PropsWithChildren<SideNavigationProps>> = (props) => {
  const { handleRejectionWithWarning } = useAlert();
  const { organization, accountListIsMemberOf } =
    useGlobalOrganizationContext();

  const getAccountRoles = useCallback(async () => {
    const accRoles = accountListIsMemberOf
      .filter((account) => !!account.account_type_id)
      .map(async (account) => {
        if (!account.account_type_id) return [];
        const accountType = await AccountTypeAPI.get(account.account_type_id);
        if (!accountType.role_id) return [];
        const role = new Role();
        role.id = accountType.role_id;
        role.name = accountType.name;
        return role;
      });

    return (await Promise.all(accRoles)).flat();
  }, [accountListIsMemberOf]);

  const [display, setDisplay] = useState<Display>({
    drawer: true,
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [accountRoles, setAccountRoles] = useState<Role[]>([]);

  useEffect(() => {
    try {
      const getRoles = async () => {
        let roles: Role[] = [];
        const publicRole = new Role();
        publicRole.id = CommonRole.PUBLIC;
        const accountRoles = await getAccountRoles();
        if (organization) {
          roles = await Roles.byUserIdAndOrganizationId(
            currentUserId(),
            organization.id,
          );
        }
        setRoles([...roles, publicRole]);
        setAccountRoles(accountRoles);
      };
      getRoles();
    } catch {
      handleRejectionWithWarning("Could not get current roles");
    }
  }, [getAccountRoles, handleRejectionWithWarning, organization]);

  const newSideNavigationProps = { ...props };
  let favicon = props.logoIcon;
  if (organization) {
    newSideNavigationProps.orgKey = organization.key;
    newSideNavigationProps.applicationName = organization.name;
    if (organization.theme) {
      newSideNavigationProps.logo =
        organization.theme.longLogoUrl ||
        organization.theme.shortLogoUrl ||
        props.logo;
      favicon = organization.theme.faviconUrl || props.logoIcon;
    } else {
      newSideNavigationProps.logo = props.logo;
    }
  } else {
    newSideNavigationProps.orgKey = props.orgKey;
    newSideNavigationProps.logo = props.logo;
    newSideNavigationProps.applicationName = props.applicationName;
  }

  useDisplayOptions({ displayIcon: favicon });
  return (
    <GlobalRoleContext.Provider
      value={{ roles, setRoles, accountRoles, setAccountRoles }}
    >
      <GlobalPageContext.Provider value={{ display, setDisplay }}>
        <DashboardLayout {...newSideNavigationProps}>
          {props.children}
        </DashboardLayout>
      </GlobalPageContext.Provider>
    </GlobalRoleContext.Provider>
  );
};

const Dashboard: FC<SideNavigationProps> = (props) => {
  return (
    <DashboardContext orgKey={props.orgKey}>
      <DashboardInner {...props}>
        <Outlet />
      </DashboardInner>
    </DashboardContext>
  );
};

export default Dashboard;
