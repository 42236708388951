import { MoreVert } from "@mui/icons-material";
import { Box, MenuItem, Typography } from "@mui/material";
import {
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { FC, useCallback, useEffect, useState } from "react";
import { AccountAPI } from "../../../api";
import {
  CommonDatagridWrapper,
  IconButtonDropdownMenu,
  RoleIcon,
} from "../../../components/elements";
import { useAlert } from "../../../lib/alert";
import { Organization, Role, User } from "../../../model";
import { AssignedRoleUserDto } from "../../../types";
import TeamMemberInvitationDialog from "./TeamMemberInvitationDialog";
import { getUserFullName } from "../../activity-log";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";

type ClientContacts = {
  accountId: string;
  organization?: Organization;
  hideMenu?: boolean;
};

const ClientContacts: FC<ClientContacts> = (props) => {
  const { success, handleRejectionWithError } = useAlert();

  const [isLoaded, setIsLoaded] = useState(false);
  const [userSelectionModel, setUserSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [teamMembers, setTeamMembers] = useState<Array<AssignedRoleUserDto>>(
    [],
  );
  const [operationsDropdownClosed, setOperationsDropdownClosed] =
    useState(true);
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);

  const teamMemberColumns = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) =>
        getUserFullName(params.row as User),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: () => {
        return "Loading";
      },
      renderCell: (params: GridRenderCellParams) => {
        const role = params.row.role as Role;
        return (
          <>
            <RoleIcon role={role} />
            <Typography sx={{ mx: 1 }}>{role.name}</Typography>
          </>
        );
      },
    },
  ];

  const reload = useCallback(() => {
    setIsLoaded(false);
    AccountAPI.listTeamMembersByAccountId(props.accountId).then(
      (userRoles: Array<AssignedRoleUserDto>) => {
        setTeamMembers(userRoles);
        setIsLoaded(true);
      },
      handleRejectionWithError,
    );
  }, [handleRejectionWithError, props.accountId]);

  useEffect(() => {
    reload();
  }, [reload]);

  const removeSelection = () => {
    setOperationsDropdownClosed(true);
    const selectedUsers = userSelectionModel.map((id) => {
      const [roleId, userId] = id.toString().split(",");
      return {
        roleId,
        userId,
      };
    });

    Promise.all(
      selectedUsers.map(({ roleId, userId }) => {
        return AccountAPI.unassignAccountRole(props.accountId, userId, roleId);
      }),
    ).then(() => {
      success("Successfully removed team members from account");
      reload();
    }, handleRejectionWithError("Failed to remove all team members from account"));
  };

  const loaded = (
    <StyledDataGrid
      disableRowSelectionOnClick
      rows={teamMembers}
      pageSizeOptions={[10]}
      checkboxSelection
      columns={teamMemberColumns}
      columnVisibilityModel={{
        id: false,
      }}
      onRowSelectionModelChange={(newSelectionModel) => {
        setUserSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={userSelectionModel}
      columnHeaderHeight={0}
      getRowId={(row) => `${row.role.id},${row.user.id}`}
      autoHeight
    />
  );

  const menu = props.hideMenu ? (
    <></>
  ) : (
    <IconButtonDropdownMenu
      element={<MoreVert />}
      closed={operationsDropdownClosed}
      setClosed={setOperationsDropdownClosed}
    >
      <MenuItem
        onClick={() => {
          setOperationsDropdownClosed(true);
          setInvitationDialogOpen(true);
        }}
      >
        Invite Team Member
      </MenuItem>
      <MenuItem onClick={removeSelection} disabled={!userSelectionModel.length}>
        Remove From Account
      </MenuItem>
    </IconButtonDropdownMenu>
  );

  return (
    <Box>
      <CommonDatagridWrapper
        isLoaded={isLoaded}
        loaded={loaded}
        title="Team Members"
        variant="card"
        PageHeaderProps={{ actions: menu, showAccountMenu: false }}
      />
      {props.organization && (
        <TeamMemberInvitationDialog
          open={invitationDialogOpen}
          setOpen={(state) => setInvitationDialogOpen(state)}
          accountId={props.accountId}
          organizationId={props.organization.id}
          onAssignmentComplete={() => reload()}
        />
      )}
    </Box>
  );
};

export default ClientContacts;
