import { FC, useCallback, useEffect, useState } from "react";
import { AccountTypeAPI } from "../../../api";
import { CommonDatagridWrapper } from "../../../components/elements";
import {
  AccountType,
  AccountTypeAffinityDto,
  AccountTypeRoleAffinity,
  AccountTypeRoleUserDtoResponse,
} from "../../../model/AccountType";
import { GridValueGetterParams, GridRenderCellParams } from "@mui/x-data-grid";
import { roleChipCellRenderer } from "../../role/OrganizationRoles";
import { Role, User } from "../../../model";
import AccountTypeUserAffinityRoleDropdown from "./AccountTypeUserAffinityRoleDropdown";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";

type AccountUserAffinityListProps = {
  accountType: AccountType;
};

const AccountUserAffinityList: FC<AccountUserAffinityListProps> = ({
  accountType,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [accountTypeAffinity, setAccountTypeAffinity] = useState<
    AccountTypeAffinityDto | undefined
  >();
  const [selectedUserByRole, setSelectedUserByRole] = useState<
    Record<string, User>
  >({});

  const accountTypeAffinityColumns = [
    {
      field: "name",
      headerName: "Role Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return roleChipCellRenderer(params.row as Role);
      },
    },
    {
      field: "role_description",
      headerName: "Role Description",
      valueGetter: (params: GridValueGetterParams): string => {
        return params.row.description;
      },
      flex: 1,
    },
    {
      field: "affinity_user",
      headerName: "Affinity User",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          accountTypeAffinity && (
            <AccountTypeUserAffinityRoleDropdown
              accountId={accountTypeAffinity?.id}
              accountTypeRoleAffinity={params.row as AccountTypeRoleAffinity}
              selectedUser={selectedUserByRole[params.row.id]}
            />
          )
        );
      },
    },
  ];

  const reload = useCallback(() => {
    setIsLoaded(false);

    const promises = Promise.all([
      AccountTypeAPI.getAffinity(accountType.id),
      AccountTypeAPI.listAccountTypeRoles(accountType.id),
    ]);

    promises.then(([affinity, accountTypeRoles]) => {
      setAccountTypeAffinity(affinity);

      const selectedUserByRole: Record<string, User> = {};
      accountTypeRoles.forEach((entry: AccountTypeRoleUserDtoResponse) => {
        selectedUserByRole[entry.role.id] = entry.user;
      });
      setSelectedUserByRole(selectedUserByRole);

      setIsLoaded(true);
    });
  }, [accountType.id]);

  useEffect(() => {
    reload();
  }, [reload]);

  const loaded = (
    <StyledDataGrid
      checkboxSelection
      disableRowSelectionOnClick
      rows={accountTypeAffinity?.roles ?? []}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={accountTypeAffinityColumns}
      columnVisibilityModel={{
        id: false,
      }}
    />
  );

  return (
    <CommonDatagridWrapper
      isLoaded={isLoaded}
      loaded={loaded}
      title={"Team Member Roles: " + accountType.name}
    />
  );
};

export default AccountUserAffinityList;
