import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { PageHeader } from "../../layout/page";
import { PageHeaderProps } from "../../layout/page/PageHeader";

type CommonDatagridWrapperProps = {
  menu?: JSX.Element;
  variant?: "default" | "card";
  isLoaded: boolean;
  loaded: JSX.Element;
  title: string;
  PageHeaderProps?: PageHeaderProps;
};

const CommonDatagridWrapper: FC<CommonDatagridWrapperProps> = ({
  PageHeaderProps = {},
  variant = "default",
  ...props
}) => {
  const menuDisplay = (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{props.menu}</Box>
  );
  const heading = variant === "card" ? "h2" : "h1";
  const content = (
    <>
      <PageHeader
        title={props.title}
        setPageTitle={variant !== "card"}
        {...PageHeaderProps}
      >
        <Typography variant={heading}>{props.title}</Typography>
      </PageHeader>
      {props.menu && (
        <Grid item flex={0} sx={{ pt: 0 }}>
          <Container sx={{ flexGrow: 1 }}></Container>
          {menuDisplay}
        </Grid>
      )}
      {props.isLoaded ? (
        <Grid
          item
          container
          flexDirection={"column"}
          flex={1}
          sx={{
            overflowY: "hidden",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        >
          {props.loaded}
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ textAlign: "center", width: "100%" }}>
          <CircularProgress data-cy={"circular-progress"} />
        </Grid>
      )}
    </>
  );
  if (variant === "card") {
    return (
      <Grid item xs={12}>
        <Card raised={true} elevation={2}>
          <Grid container flexDirection={"column"}>
            {content}
          </Grid>
        </Card>
      </Grid>
    );
  }
  return content;
};

export default CommonDatagridWrapper;
