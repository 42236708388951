import { PieChart } from "@mui/x-charts";
import HeroContainer from "../../../../components/elements/HeroContainer";
import { useTheme } from "@mui/material/styles";
import { DashboardElementPieChart } from "../../../../model/Dashboard";
import { useMemo } from "react";
import { Container, Skeleton } from "@mui/material";

type PieChartCardProps = {
  element: DashboardElementPieChart;
};

export type PieChartData = { value: number; label: string }[];

export const PieChartCard = ({ element: { data } }: PieChartCardProps) => {
  const theme = useTheme();
  const metricData = useMemo<PieChartData>(() => {
    return (data ?? []).map(({ label, count }) => ({ label, value: count }));
  }, [data]);

  if (metricData.length == 0) return <PieChartCardSkeleton />;

  return (
    <HeroContainer
      displayTopographic
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "22em",
      }}
    >
      <PieChart
        height={300}
        margin={{ bottom: 10, top: 10, left: 10, right: 10 }}
        slotProps={{
          legend: { hidden: true },
        }}
        series={[{ data: metricData }]}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.success.main,
          theme.palette.success.light,
        ]}
      />
      <PieChart
        height={300}
        margin={{ bottom: 10, top: 10, left: 10, right: 10 }}
        slotProps={{
          legend: {
            direction: "column",
            itemMarkWidth: 15,
            itemMarkHeight: 15,
            labelStyle: { fontSize: 14 },
            position: {
              vertical: "middle",
              horizontal: "left",
            },
          },
        }}
        series={[{ data: metricData.map((point) => ({ ...point, value: 0 })) }]}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.success.main,
          theme.palette.success.light,
        ]}
      />
    </HeroContainer>
  );
};

export const PieChartCardSkeleton = () => (
  <HeroContainer
    displayTopographic
    sx={{
      height: 1,
      width: 1,
      display: "flex",
      minWidth: "22em",
      maxHeight: "20em",
    }}
  >
    <Container
      disableGutters
      sx={{
        width: 0.5,
        display: "flex",
        alignItems: "center",
        pb: 4,
      }}
    >
      <Skeleton
        variant="circular"
        sx={{
          maxWidth: "18em",
          minWidth: "8em",
          width: "15em",
          aspectRatio: "1/1",
          height: "auto",
          ml: 4,
          mr: 1,
        }}
      />
    </Container>
    <Container
      disableGutters
      sx={{
        width: 0.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        px: 2,
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1.4rem" }} />
    </Container>
  </HeroContainer>
);
