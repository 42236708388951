import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.min.js";

type PdfViewerProps = {
  source: string;
  scale?: number;
  showButtons?: boolean;
};

const PdfViewer = ({
  source,
  scale = 1,
  showButtons = true,
}: PdfViewerProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const nextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const previousPage = () => {
    setPageNumber((prev) => prev - 1);
  };
  return (
    <Box>
      <Box
        onClick={() => window.open(source, "_blank")}
        sx={{ cursor: "pointer" }}
      >
        <Document
          file={source}
          onLoadSuccess={(pdf) => setNumPages(pdf.numPages)}
        >
          <Page scale={scale} pageNumber={pageNumber} />
        </Document>
      </Box>

      {numPages > 1 && showButtons && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant="subtitle2">
            {`Page ${pageNumber || (numPages ? 1 : "--")} of ${
              numPages || "--"
            }`}
          </Typography>
          <IconButton disabled={pageNumber >= numPages} onClick={nextPage}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PdfViewer;
