import { CognitoUser } from "amazon-cognito-identity-js";
import User from "../model/User";
import Invitation from "../model/Invitation";
import { Role } from "../model";
import { Account } from "../model/Account";

export type ProcessRoute = {
  processId: string;
};

export type UserRoute = {
  userId: string;
};

export enum ResourceModificationOperationType {
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

export const record = <Prop extends PropertyKey, Value extends string>(
  prop: Prop,
  value: Value,
) => ({ [prop]: value }) as Record<Prop, Value>;

export interface ChangePasswordState {
  userAttributes: unknown;
  cognitoUser: CognitoUser;
}

export type ProcessDto = {
  id: string;
  name: string;
  description: string;
  icon?: string;
  entity_id?: string;
  entity_type?: string;
  category_id?: string;
};

export type NavPath = {
  title: string;
  titleIntl?: JSX.Element;
  link: string;
  linkTo?: (...args: string[]) => string;
  icon?: JSX.Element;
  requiresAdmin?: boolean;
  visible: boolean;
  location?: "top" | "bottom";
};

export type SideNavigationProps = {
  logo?: string;
  logoIcon?: string;
  offset?: number;
  orgKey?: string;
  applicationName?: string;
};

export type CombinedUserAndInvitationModel = User | Invitation;
export type CombinedAccountAndInvitationModel = Account | Invitation;

export enum UserOrganizationRelationshipType {
  Member = "Member",
  Account = "Account",
  Invitation = "Invitation",
}

export type AssignedRoleUserDto = {
  user: User;
  role: Role;
};

export enum CommonRole {
  PUBLIC = "public",
  AUTOMATION = "automation",
  PROCESS_DEPENDENCY = "process_dependency",
}
