import { useState } from "react";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";

import IconButtonDropdownMenu from "../../../elements/IconButtonDropdownMenu";
import MenuItem from "@mui/material/MenuItem";
import { useAlert } from "../../../../lib/alert";
import { federatedLogoutEndpoint, logout } from "../../../../lib/auth";
import { Box, Divider } from "@mui/material";
import PATHS from "../../../navigation/_paths";
import { Link as RouterLink } from "react-router-dom";
import { UserIcon } from "../../../elements";

const AccountButton = () => {
  const { user } = useGlobalUserContext();
  const { handleRejectionWithError } = useAlert();

  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(true);

  const handleLogout = () => {
    logout().then(() => {
      window.location.href = federatedLogoutEndpoint(window.location.origin);
    }, handleRejectionWithError("Could not complete logout"));
  };
  if (!user?.id) {
    return;
  }
  return (
    <Box
      sx={{
        "@media print": {
          display: "none", // Make all content visible for printing
        },
      }}
    >
      <IconButtonDropdownMenu
        element={<UserIcon user={user} />}
        closed={isMenuClosed}
        setClosed={setIsMenuClosed}
        size="large"
      >
        <MenuItem>{user.email}</MenuItem>
        <Divider />
        <MenuItem component={RouterLink} to={PATHS.PREFERENCES.link}>
          Preferences
        </MenuItem>
        <MenuItem data-cy={"logout-btn"} onClick={handleLogout}>
          Log out
        </MenuItem>
      </IconButtonDropdownMenu>
    </Box>
  );
};

export default AccountButton;
