import { Link } from "react-router-dom";
import { environment } from "../../../util";
import PATHS from "../../navigation/_paths";
import { AppBar, Box } from "@mui/material";
import AccountButton from "./components/AccountButton";

export type TopBarProps = {
  logo?: string;
  height?: number;
};

const TopBar = ({ height = 0 }: TopBarProps) => {
  return (
    <>
      {height > 0 && (
        <AppBar
          sx={{
            bgcolor: "white",
            borderBottom: "8px solid",
            borderColor: "primary.main",
          }}
          position="fixed"
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box sx={{ flex: 1 }}>
              <Link data-cy={"dashboard-link"} to={PATHS.HOME.link}>
                <img
                  data-cy={"app-logo"}
                  src={environment.app.logo}
                  style={{
                    height: `${(height - 1) * 8}px`,
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "16px",
                  }}
                />
              </Link>
            </Box>
            <Box sx={{ flex: 0 }}>
              <AccountButton />
            </Box>
          </Box>
        </AppBar>
      )}
    </>
  );
};

export default TopBar;
