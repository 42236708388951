import { FC } from "react";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import OrganizationProcessExecutions from "./OrganizationProcessExecutions";
import { useGlobalOrganizationContext } from "edo-platform-frontend/src/hooks/useGlobalOrganizationContext";
import { PageContainer } from "../../../components/layout";
import ChatUI from "../../../components/chat/ChatUI";
import useFeature from "../../../hooks/useFeature";

const OrganizationProcessExecutionsPage: FC<unknown> = () => {
  useDisplayOptions({});
  // TODO - filter executions by organization
  const { organization } = useGlobalOrganizationContext();
  const chatFeature = useFeature("chat");

  return (
    <PageContainer variant="full">
      <OrganizationProcessExecutions currentOrganization={organization} />
      {chatFeature && <ChatUI />}
    </PageContainer>
  );
};

export default OrganizationProcessExecutionsPage;
