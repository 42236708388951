import { FC, PropsWithChildren } from "react";
import {
  Avatar,
  Box,
  SxProps,
  styled,
  Tooltip,
  Typography,
  TooltipProps,
} from "@mui/material";
import { AccountCircle, Circle } from "@mui/icons-material";
import { User } from "../../model";
import getUserFullName from "../../features/activity-log/utils/getUserFullName";
import { sha256 } from "js-sha256";

type UserIconProps = {
  user?: User;
  sx?: SxProps;
  toolTipPosition?: TooltipProps["placement"];
  title?: JSX.Element;
  defaultIcon?: JSX.Element;
  size?: "small" | "large";
};

export const CircleIcon = styled(Circle, {
  shouldForwardProp: (prop) => prop !== "role",
})(({ theme }) => ({
  borderRadius: "50%",
  position: "relative",
  zIndex: 1,
  backgroundColor: theme.palette.primary.dark,
  fill: theme.palette.primary.light,
}));

const getGravatarProfilePicture = (email: string) => {
  const hash = sha256(String(email).trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=mp`;
};

const UserIcon: FC<PropsWithChildren<UserIconProps>> = ({
  sx,
  user,
  toolTipPosition = "bottom",
  title,
  defaultIcon,
  size = "small",
}) => {
  const tooltipText = (
    <Box sx={{ textAlign: "center" }}>
      <Typography sx={{ fontSize: (theme) => theme.spacing(1.25) }}>
        {title ?? user ? getUserFullName(user) : "Unassigned"}
      </Typography>
    </Box>
  );

  const profilePictureURL = user
    ? user?.profile_picture || getGravatarProfilePicture(user?.email ?? "")
    : "";
  const len = size === "small" ? 3 : 6;
  return (
    <Tooltip title={title ?? tooltipText} placement={toolTipPosition}>
      {user ? (
        <Avatar
          src={profilePictureURL}
          imgProps={{
            // this needs to be here due to 403 forbidden errors that are sometimes thrown when using the link google provides for the user's profile picture
            // this seems to be a common problem when using the link google provides, but if we know of a better way to resolve this error then let's change this
            referrerPolicy: "no-referrer",
          }}
          sx={{
            height: (theme) => theme.spacing(len),
            width: (theme) => theme.spacing(len),
            ...sx,
          }}
        >
          {/* fallback image in case profile picture can't be loaded */}
          {defaultIcon ?? <AccountCircle sx={{ color: "text.primary" }} />}
        </Avatar>
      ) : (
        defaultIcon ?? <AccountCircle sx={{ color: "text.primary" }} />
      )}
    </Tooltip>
  );
};

export default UserIcon;
