import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "../components/layout";
import RequireAuth from "../components/require-auth";
import { App } from "../features/app";
import {
  OrganizationPage,
  PublicOrganizationLayout,
  OrganizationAccountTypePublicPage,
  OrganizationCategoryPublicProcessPage,
  GlobalOrganizationProvider,
} from "../features/organization";
import UserDashboardPage from "../features/user/UserDashboardPage";
import {
  ForgotPasswordPage,
  ForgotPasswordEmailSentPage,
  RegisterPage,
  SignInPage,
  ForgotPasswordResetPage,
  UserChangePasswordPage,
  UserPage,
  VerificationPage,
} from "../features/user";
import { ProcessPage, ProcessViewPage } from "../features/process";
import { PublicProcessExecutionPage } from "../features/public-executor/PublicProcessExecutionPage";
import ProcessExecutionViewPage from "../features/process/components/ProcessExecutionViewPage";
import ProcessExecutionLongTaskFormPage from "../features/process/components/ProcessExecutionLongTaskFormPage";

import RoleListPage from "../features/role/RoleListPage";
import { IntegrationsPage } from "../features/integrations";
import {
  AccountListPage,
  AccountsIsMemberOfListPage,
  AccountTypeAccountsListPage,
  AccountTypeProcessListPage,
} from "../features/accounts";
// import KanbanBoardView from "../features/kanban-board/components/KanbanBoardView";
import TaskPage from "../features/task/components/TaskPage";
import ApiKeysPage from "../features/user/ApiKeysPage";
import SingleProcessWrapper, {
  SingleProcessViewType,
} from "../features/process/components/SingleProcessPage/SingleProcessWrapper";
import CreateOrganizationPage from "../features/organization/CreateOrganizationPage";
import UpdateOrganizationPage from "../features/organization/UpdateOrganizationPage";
import NotFound from "../features/app/NotFound";
import AccountTypePage from "../features/accounts/AccountTypePage";
import AccountTypeAffinityPage from "../features/accounts/AccountTypeAffinityPage";
import { environment } from "../util";
import { PublicOrganizationPage } from "../features/organization/";
import ProcessExecutionLongTaskFormSharePage from "../features/process/components/ProcessExecutionLongTaskFormSharePage";
import SharedPage from "../features/app/SharedPage";
import AccountTaskExecutionPage from "../features/process/AccountTaskExecutionPage";
import OrganizationProcessExecutionsPage from "../features/process/components/OrganizationProcessExecutionsPage";
import OnboardingExecutorPage from "../features/public-executor/OnboardingExecutionPage";
import AccountProfilePage, {
  AccountProfileSubpage,
  AccountProfileViewer,
} from "../features/accounts/AccountProfilePage";
import ConfirmOrganizationEmailPage from "../features/organization/ConfirmOrganizationEmailPage";
import useFeature from "../hooks/useFeature";
import ManageInvitationPage from "../features/invitations/ManageInvitationPage";
import NavigateHome from "../components/navigation/NavigateHome";
import { DashboardRoute } from "../features/dashboard/DashboardRoute";
import UserPreferencePage from "../features/user/UserPreferencePage";
import { GoogleAuthRedirect } from "../features/user/components/GoogleAuth";
import InternalOrganizationLayout from "../features/organization/InternalOrganizationLayout";

const showCreateAccount = true;
/*  !!localStorage.getItem("runway.register") ||
  process.env.REACT_APP_ENVIRONMENT == "local";*/

const getAccountPageRoutes = (viewer: AccountProfileViewer) => {
  return (
    <>
      <Route
        path="overview"
        element={
          <AccountProfilePage
            view={AccountProfileSubpage.Overview}
            viewer={viewer}
          />
        }
      />
      <Route
        path="program"
        element={
          <AccountProfilePage
            view={AccountProfileSubpage.ProgramViewer}
            viewer={viewer}
          />
        }
      />
      {viewer === AccountProfileViewer.Admin && (
        <Route
          path="program/editor"
          element={
            <AccountProfilePage
              view={AccountProfileSubpage.ProgramEditor}
              viewer={viewer}
            />
          }
        />
      )}
      <Route path="" element={<Navigate to="overview" replace={true} />} />
      <Route path="*" element={<NotFound />} />
    </>
  );
};

const OrganizationRoutes = () => {
  const canViewDashboard = useFeature("dashboard");
  return (
    <Routes>
      <Route
        path=":organizationId/confirm"
        element={<ConfirmOrganizationEmailPage />}
      />
      {/* 2. Hide organization management pages when enterprise account exists */}

      <Route path="manage" element={<UpdateOrganizationPage />} />
      {/* End #2 */}
      <Route
        path="accounts/byType/:accountTypeKey"
        element={<AccountTypeAccountsListPage />}
      />
      <Route
        path="accounts/byType/:accountTypeKey/processes"
        element={<AccountTypeProcessListPage />}
      />
      <Route
        path="executions"
        element={<OrganizationProcessExecutionsPage />}
      />
      {canViewDashboard && (
        <Route path="dashboards/:dashboardKey" element={<DashboardRoute />} />
      )}
      <Route path="roles" element={<RoleListPage />} />
      <Route path="members" element={<OrganizationPage />} />
      <Route path="accounts/:subjectId">
        {getAccountPageRoutes(AccountProfileViewer.Admin)}
      </Route>
      <Route element={<AccountListPage />} path="accounts" />
      <Route path="processes/:processId" element={<ProcessViewPage />} />
      <Route path="processes" element={<ProcessPage />} index />
      <Route path="processes/:processId/tasks/:taskId" element={<TaskPage />} />
      <Route
        path="processes/:processId/tasks/:taskId/elements/:elementId"
        element={<TaskPage />}
      />
      <Route
        path="processes/:processId/tasks/:taskId/elements/:elementId/keys/:key"
        element={<TaskPage />}
      />
      <Route path="executions/:processId">
        {localStorage.getItem("runway.singleProcess") == "true" && (
          <>
            <Route path="" element={<Navigate to="table" />} />
            <Route
              path="table"
              element={
                <SingleProcessWrapper view={SingleProcessViewType.TABLE} />
              }
            />
            <Route
              path="board"
              element={
                <SingleProcessWrapper view={SingleProcessViewType.BOARD} />
              }
            />
            <Route path="*" element={<NotFound />} />
          </>
        )}
        <Route
          path=":processExecutionId"
          element={<ProcessExecutionViewPage />}
        />
        <Route
          path=":processExecutionId/tasks/:taskId/form"
          element={<ProcessExecutionLongTaskFormPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          path="user"
          element={
            <RequireAuth>
              <Dashboard
                applicationName={
                  environment.app?.name ?? environment.organization?.name ?? ""
                }
                logo={environment.app?.logo}
                logoIcon={environment.app?.logoIcon}
              />
            </RequireAuth>
          }
        >
          <Route path="preferences" element={<UserPreferencePage />} />
          {/* 1. Replace ProcessExecutionListPage in ECP */}
          <Route path="dashboard" element={<UserDashboardPage />} />
          <Route path="organizations/accounts/:subjectId">
            {getAccountPageRoutes(AccountProfileViewer.User)}
          </Route>
          <Route
            path="executions/:processExecutionId"
            element={<ProcessExecutionViewPage />}
          />
          <Route
            path="organizations/accounts/member/:userId"
            element={<AccountsIsMemberOfListPage />}
          />
          <Route
            path="organizations/create"
            element={<CreateOrganizationPage />}
          />
          <Route path="organization" element={<InternalOrganizationLayout />}>
            <Route path="*" element={<OrganizationRoutes />} />
          </Route>
          <Route path="integrations" element={<IntegrationsPage />} />
          <Route path="apikeys" element={<ApiKeysPage />} />
          <Route path="account-types" element={<AccountTypePage />} />
          <Route
            path="account-type/:accountTypeId/affinity"
            element={<AccountTypeAffinityPage />}
          />
          <Route
            path="share/:taskExecutionId/form"
            element={<ProcessExecutionLongTaskFormSharePage />}
          />
          <Route index element={<NavigateHome />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="verify" element={<VerificationPage />} />
        {showCreateAccount && (
          <Route path="register" element={<RegisterPage />} />
        )}
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="google-auth-redirect" element={<GoogleAuthRedirect />} />
        <Route
          path="forgot-password"
          element={
            <ForgotPasswordPage
              clientMetadata={{
                whitelabelUrl: window.location.origin,
                sesConfigId: environment.app.sesConfigId ?? "",
                logoUrl: environment.app.logo
                  ? window.location.origin + environment.app.logo
                  : "",
              }}
            />
          }
        />
        <Route
          path="forgot-password-email-sent"
          element={<ForgotPasswordEmailSentPage />}
        />
        <Route path="reset-password" element={<ForgotPasswordResetPage />} />
        <Route
          path="user/profile/"
          element={
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          }
        />
        <Route
          path="new-password-challenge"
          element={<UserChangePasswordPage />}
        />
        <Route
          path="manage-invitation"
          element={
            <GlobalOrganizationProvider>
              <ManageInvitationPage />
            </GlobalOrganizationProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
        {/* 3. Move submit an idea pages into common public executor template */}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="share" element={<SharedPage logo={environment.app.logo} />}>
        <Route
          path=":taskExecutionId/form"
          element={<ProcessExecutionLongTaskFormSharePage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="/:orgKey/onboarding/:publicExecutorId"
        element={<OnboardingExecutorPage />}
      />
      <Route path="/:orgKey/" element={<PublicOrganizationLayout />}>
        <Route path="dashboards/:dashboardKey" element={<DashboardRoute />} />
        <Route
          path="works/:publicExecutorId"
          element={<PublicProcessExecutionPage />}
        >
          <Route
            path="executions/:processExecutionId"
            element={<PublicProcessExecutionPage />}
          />
        </Route>
        <Route
          path="for/:accountTypeKey/"
          element={<OrganizationAccountTypePublicPage />}
        />
        <Route
          path="category/:categoryId"
          element={<OrganizationCategoryPublicProcessPage />}
        />
        <Route
          path="share"
          element={<SharedPage logo={environment.app.logo} />}
        >
          <Route
            path=":processExecutionId/task/:taskId"
            element={<AccountTaskExecutionPage showAttachments={false} />}
          />
          <Route
            path=":taskExecutionId/form"
            element={<ProcessExecutionLongTaskFormSharePage />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route index element={<PublicOrganizationPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
