// React layout component for internal organization pages

import { Box } from "@mui/material";
import { Outlet } from "react-router";
import ChatDrawer from "../messaging/ChatDrawer";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import OrganizationDrawerMenu from "./OrganizationDrawerMenu";

const DRAWER_WIDTH = 240;

const InternalOrganizationLayout = () => {
  const { isMember, organization } = useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);
  const drawerWidth = isMemberOfOrg ? DRAWER_WIDTH : 0;
  return (
    <Box
      id="page-outlet"
      sx={{
        ml: `${drawerWidth}px`,
        height: "100%",
        "@media print": {
          ml: 0,
        },
      }}
    >
      {isMemberOfOrg && <OrganizationDrawerMenu drawerWidth={drawerWidth} />}
      <Outlet />
      <ChatDrawer sx={{ width: "600px" }} />
    </Box>
  );
};

export default InternalOrganizationLayout;
