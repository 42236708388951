import { Chip, Container, Skeleton, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DashboardElementProcessCountCard } from "../../../../model/Dashboard";
import HeroContainer from "../../../../components/elements/HeroContainer";
import { Interval, intervalString } from "../../../../model/Metric";
import grey from "@mui/material/colors/grey";

type ProcessCountCardProps = {
  element: DashboardElementProcessCountCard;
};

export type ProcessCountCardData = {
  name: string;
  executionCount: number;
  avgTime: Interval;
  avgTimeString: string;
  avgTimeValue: number;
};

export const ProcessCountCard: FC<ProcessCountCardProps> = ({ element }) => {
  const theme = useTheme();
  const [metricData, setMetricData] = useState<ProcessCountCardData[]>([]);
  const [largestDataValue, setLargestDataValue] = useState(1);

  useEffect(() => {
    switch (element.config.dataSource) {
      case "config": {
        setLargestDataValue(
          (element.data ?? []).reduce((prev, { avgTime }) => {
            if (avgTime.value > prev) {
              return avgTime.value;
            }
            return prev;
          }, largestDataValue),
        );
        setMetricData(
          (element.data ?? []).map<ProcessCountCardData>(
            ({ executionCount, avgTime, name }) => {
              return {
                name: name,
                executionCount: executionCount,
                avgTime: avgTime.interval,
                avgTimeString: intervalString(avgTime.value, "highest"),
                avgTimeValue: avgTime.value ?? 0,
              };
            },
          ),
        );
      }
    }
  }, [element.config.dataSource, element.data, largestDataValue]);

  if (metricData.length == 0) return <ProcessCountCardSkeleton />;

  return (
    <HeroContainer
      displayTopographic
      sx={{ p: 3, display: "flex", flexDirection: "row" }}
    >
      <Container disableGutters>
        {metricData.map(
          ({ name, executionCount, avgTimeString, avgTimeValue }, index) => (
            <Container
              disableGutters
              key={index}
              sx={{
                py: 1,
                display: "flex",
                borderBottom: `solid ${grey[400]}`,
                borderBottomWidth: index == metricData.length - 1 ? 0 : 1,
              }}
            >
              <Typography sx={{ display: "inline-block", maxWidth: 0.2 }}>
                {name}
              </Typography>
              <Chip
                label={executionCount}
                size="small"
                sx={{ ml: 1, pt: "2px", display: "inline-block" }}
              />
              <Container
                disableGutters
                sx={{
                  width: 0.75,
                  mr: 0,
                }}
              >
                {avgTimeValue != 0 && avgTimeValue / largestDataValue < 0.15 ? (
                  <>
                    <Chip
                      sx={{
                        bgcolor: theme.palette.success.light,
                        color: theme.palette.getContrastText(
                          theme.palette.success.light,
                        ),
                        width: `${(100 * avgTimeValue) / largestDataValue}%`,
                      }}
                    />
                    <Typography
                      sx={{ display: "inline-block", fontSize: "small", ml: 1 }}
                    >
                      {avgTimeString}
                    </Typography>
                  </>
                ) : (
                  <Chip
                    label={avgTimeString}
                    sx={{
                      bgcolor: theme.palette.success.light,
                      color: theme.palette.getContrastText(
                        theme.palette.success.light,
                      ),
                      width: `${(100 * avgTimeValue) / largestDataValue}%`,
                    }}
                  />
                )}
              </Container>
            </Container>
          ),
        )}
      </Container>
    </HeroContainer>
  );
};

export const ProcessCountCardSkeleton = () => (
  <HeroContainer
    displayTopographic
    sx={{ p: 3, display: "flex", flexDirection: "row" }}
  >
    <Container disableGutters>
      {[0.4, 0.9, 0.7].map((percentComplete, index) => (
        <Container
          disableGutters
          key={index}
          sx={{
            py: 1,
            display: "flex",
            borderBottom: `solid ${grey[400]}`,
            borderBottomWidth: index == 2 ? 0 : 1,
          }}
        >
          <Skeleton
            variant="text"
            sx={{ display: "inline-block", width: percentComplete / 5 }}
          />
          <Skeleton
            variant="circular"
            sx={{
              ml: 1,
              pt: "2px",
              display: "inline-block",
              height: "24px",
              width: "24px",
            }}
          />
          <Container
            disableGutters
            sx={{
              width: 0.75,
              mr: 0,
            }}
          >
            <Skeleton
              variant="rounded"
              sx={{
                ml: 1,
                pt: "2px",
                display: "inline-block",
                width: percentComplete,
              }}
            />
          </Container>
        </Container>
      ))}
    </Container>
  </HeroContainer>
);
