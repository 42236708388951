import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import { environment } from "../../../util";

export type SideDrawerProps = React.PropsWithChildren<{
  drawerWidth: number;
}>;

const SideDrawer = ({ drawerWidth, children }: SideDrawerProps) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  const isEnterprise = !!environment.organization?.key;

  return (
    <Box
      sx={{ display: "flex", width: drawerWidth + "px", displayPrint: "none" }}
    >
      <Drawer
        data-cy={"side-drawer"}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: "background.drawer",
            width: drawerWidth,
            ml: isEnterprise ? 0 : 7,
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {children}
      </Drawer>
    </Box>
  );
};

export default SideDrawer;
