import { FC, useCallback, useEffect, useState } from "react";
import {
  CommonDatagridWrapper,
  IconButtonDropdownMenu,
  RoleIcon,
} from "../../../components/elements";
import {
  GridRowSelectionModel,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Organization, Role, User } from "../../../model";
import { AccountAPI } from "../../../api";
import { useAlert } from "../../../lib/alert";
import { Box, MenuItem, Typography } from "@mui/material";
import InvitationDialog from "../../organization/components/InvitationDialog";
import { MoreVert } from "@mui/icons-material";
import { getUserFullName } from "../../activity-log";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";

type ClientContacts = {
  accountId: string;
  organization?: Organization;
  hideMenu?: boolean;
};

const ClientContacts: FC<ClientContacts> = (props) => {
  const { success, handleRejectionWithError } = useAlert();

  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [userSelectionModel, setUserSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [clientContacts, setClientContacts] = useState<Array<User>>([]);
  const [operationsDropdownClosed, setOperationsDropdownClosed] =
    useState(true);

  const clientContactsColumns = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) =>
        getUserFullName(params.row as User),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: () => {
        return "Loading";
      },
      renderCell: (params: GridRenderCellParams) => {
        const roles = params.row.roles as Array<Role>;
        return (
          <>
            {roles.map((r) => (
              <>
                <RoleIcon key={r.id} role={r} />
                <Typography sx={{ mx: 1 }}>{r.name}</Typography>
              </>
            ))}
          </>
        );
      },
    },
  ];

  const reload = useCallback(() => {
    setIsLoaded(false);
    AccountAPI.listUsersByAccountId(props.accountId).then((users) => {
      setClientContacts(users);
      setIsLoaded(true);
    }, handleRejectionWithError);
  }, [props.accountId, handleRejectionWithError]);

  useEffect(() => {
    reload();
  }, [reload]);

  const loaded = (
    <StyledDataGrid
      disableRowSelectionOnClick
      rows={clientContacts}
      pageSizeOptions={[10]}
      checkboxSelection
      columns={clientContactsColumns}
      columnVisibilityModel={{
        id: false,
      }}
      onRowSelectionModelChange={(newSelectionModel) => {
        setUserSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={userSelectionModel}
      columnHeaderHeight={0}
      autoHeight
    />
  );

  const removeSelection = () => {
    setOperationsDropdownClosed(true);
    Promise.all(
      userSelectionModel.map((userId) => {
        return AccountAPI.removeUser(props.accountId, userId.toString());
      }),
    ).then(() => {
      success("Successfully removed users from account");
      reload();
    }, handleRejectionWithError("Failed to remove all users from account"));
  };

  const menu = props.hideMenu ? (
    <></>
  ) : (
    <IconButtonDropdownMenu
      element={<MoreVert />}
      closed={operationsDropdownClosed}
      setClosed={setOperationsDropdownClosed}
    >
      <MenuItem
        onClick={() => {
          setOperationsDropdownClosed(true);
          setInvitationDialogOpen(true);
        }}
      >
        Invite Account Member
      </MenuItem>
      <MenuItem onClick={removeSelection} disabled={!userSelectionModel.length}>
        Remove From Account
      </MenuItem>
    </IconButtonDropdownMenu>
  );

  return (
    <Box>
      <CommonDatagridWrapper
        isLoaded={isLoaded}
        loaded={loaded}
        title="Client Contacts"
        variant="card"
        PageHeaderProps={{ actions: menu, showAccountMenu: false }}
      />
      {props.organization && (
        <InvitationDialog
          organization={props.organization}
          accountId={props.accountId}
          open={invitationDialogOpen}
          setInvitationDialogOpen={(openState: boolean) => {
            setInvitationDialogOpen(openState);
          }}
          handleInvitation={() => {
            reload();
          }}
        />
      )}
    </Box>
  );
};

export default ClientContacts;
