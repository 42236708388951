import * as MuiIcons from "@mui/icons-material";
import { SvgIcon, SvgIconProps } from "@mui/material";
type MuiIconType = typeof import("@mui/icons-material/index");

export type DynamicIconProps = { name: string } & SvgIconProps;

const DynamicIcon = ({ name, ...props }: DynamicIconProps) => {
  const iconName: keyof MuiIconType = name as unknown as keyof MuiIconType;
  if (!(iconName in MuiIcons)) {
    return <SvgIcon />;
  }
  const IconComponent = MuiIcons[
    iconName
  ] as unknown as MuiIcons.SvgIconComponent;
  return <IconComponent data-cy={"dynamic-icon"} {...props} />;
};
export { MuiIcons };
export default DynamicIcon;
