import { Button, Typography } from "@mui/material";
import invitations from "../../../api/invitations";
import { useAlert } from "../../../lib/alert";
import Invitation from "../../../model/Invitation";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

type UserSelectionInviteButtonProps = {
  invitation: Invitation;
  onInvite?: (invitation: Invitation) => void;
};

const isEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
};

const UserSelectionInviteButton = ({
  invitation,
  onInvite,
}: UserSelectionInviteButtonProps) => {
  const { success } = useAlert();
  if (!isEmail(invitation.target_user_email)) {
    return (
      <Typography variant="subtitle2">{`No results found. Enter an email address to invite a new user.`}</Typography>
    );
  }
  return (
    <Button
      onClick={async () => {
        const userInvitation = await invitations.create(invitation);
        onInvite && onInvite(userInvitation);
        success(`Invited ${invitation.target_user_email}`);
      }}
      variant={"outlined"}
      size={"small"}
      sx={{ textTransform: "unset" }}
      fullWidth
    >
      <AddCircleOutlineIcon sx={{ mr: 1 }} />

      <Typography variant="body2">
        {"Invite "}
        <Typography
          component="span"
          variant="body2"
          sx={{ fontWeight: "bold" }}
        >
          {invitation.target_user_email}
        </Typography>
      </Typography>
    </Button>
  );
};

export default UserSelectionInviteButton;
