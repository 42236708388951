import { FC, useMemo } from "react";
import { RenderNextTask } from "../../../../hooks/useGlobalRoleContext";
import { Process } from "../../../../model/Process";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import ProcessExecutionDataGrid, {
  DEFAULT_GRID_COLUMNS,
} from "../ProcessExecutionDataGrid";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";
import { GridColDef } from "@mui/x-data-grid";

type SingleProcessTablePageProps = {
  process: Process;
  executions: ProcessExecution[];
};

const SingleProcessTablePage: FC<SingleProcessTablePageProps> = ({
  executions,
}) => {
  const { user } = useGlobalUserContext();
  const gridColumns: GridColDef[] = useMemo(() => {
    const gridColumns: GridColDef[] = [
      DEFAULT_GRID_COLUMNS.TITLE,
      DEFAULT_GRID_COLUMNS.ACCOUNT,
      DEFAULT_GRID_COLUMNS.PROGRESS,
    ];
    gridColumns.push({
      field: "nextTask",
      headerName: "Next Task",
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      minWidth: 150,
      headerClassName: "process-grid-header",
      renderCell: RenderNextTask,
    });
    gridColumns.push(DEFAULT_GRID_COLUMNS.ASSIGNEE(user));
    gridColumns.push(DEFAULT_GRID_COLUMNS.UPDATED);
    return gridColumns;
  }, [user]);

  return (
    <ProcessExecutionDataGrid
      columns={gridColumns}
      rows={executions}
      sx={{ flexGrow: 1 }}
    />
  );
};

export default SingleProcessTablePage;
