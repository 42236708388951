import { User } from "../model";
import API from "./base";

const register = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/users/register").then(() => resolve(), reject);
  });
};

const get = (userId: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    API.get("/users/" + userId).then((result) => {
      resolve(result.data.payload as User);
    }, reject);
  });
};

const byRoleId = (roleId: string, filter?: string): Promise<Array<User>> => {
  return new Promise((resolve, reject) => {
    API.get("/users/byRoleId/" + roleId, {
      params: { filter: filter ? `${filter}` : undefined },
    }).then((result) => {
      resolve(result.data.payload as Array<User>);
    }, reject);
  });
};

const leaveOrganization = (userId: string, orgId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(`/users/${userId}/leaveOrganization/${orgId}`).then(
      () => resolve(),
      reject,
    );
  });
};

export default {
  register: register,
  get: get,
  byRoleId: byRoleId,
  leaveOrganization: leaveOrganization,
};
