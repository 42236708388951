import {
  Box,
  Divider,
  Drawer,
  IconButton,
  SxProps,
  Typography,
} from "@mui/material";
import {
  SideDetailsContainer,
  SideDetailsSection,
} from "../process/components/side-details";
import Chat from "./Chat";
import { NavigateNext } from "@mui/icons-material";
import { globalChatOpenAtom } from "./state/globalChatOpenAtom";
import { useAtom } from "jotai";
import { globalChatThreadAtom } from "./state/globalChatThreadAtom";
import { globalChatSubjectAtom } from "./state/globalChatSubjectAtom";
import { useEffect } from "react";
import { ChatAPI } from "../../api";
import { ChatDto } from "./model";

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        left: -18,
        top: 19,
        backgroundColor: (theme) => theme.palette.background.drawer,
        borderColor: (theme) => theme.palette.divider,
        borderStyle: "solid",
        borderWidth: "1px",
        color: (theme) => theme.palette.grey[500],
        "&.MuiButtonBase-root": {
          "&.MuiIconButton-root": {
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[400],
            },
          },
        },
      }}
      size="small"
    >
      <NavigateNext />
    </IconButton>
  );
};

const DRAWER_TOP = "0px";

const ChatDrawer = ({ sx }: { sx: SxProps }) => {
  const [drawerOpen, setDrawerOpen] = useAtom(globalChatOpenAtom);
  const [threadId, setThreadId] = useAtom(globalChatThreadAtom);
  const [globalChatSubject] = useAtom(globalChatSubjectAtom);
  useEffect(() => {
    if (!threadId && globalChatSubject?.id) {
      // This call will create a new chat for the subject on the backend
      ChatAPI.getChatsForSubject(globalChatSubject?.id as string).then(
        (subjectThreads) => {
          if (subjectThreads && subjectThreads.length > 0) {
            setThreadId(subjectThreads[0].id);
          }
        },
        () => {
          // TODO - display warning in chat
        },
      );
    }
  }, [threadId, globalChatSubject?.id, setThreadId]);
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      variant="persistent"
      sx={{
        mt: DRAWER_TOP,
        "& .MuiDrawer-paper": {
          marginTop: DRAWER_TOP,
          height: `calc(100% - ${DRAWER_TOP})`,
          overflow: "visible",
          boxShadow: "0px 10px 25px rgba(0,0,0,0.5)",
        },
      }}
    >
      <CloseButton onClose={() => setDrawerOpen(false)} />
      <SideDetailsContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          ...sx,
        }}
      >
        <Divider />
        <SideDetailsSection sx={{ height: 68 }}>
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
            {(globalChatSubject?.name as string) ?? "Thread"}
          </Typography>
        </SideDetailsSection>
        <Divider />
        <Box position="relative" flex="1" overflow="hidden">
          {threadId && (
            <Chat
              threadId={threadId}
              chat={globalChatSubject as unknown as ChatDto}
              showExecutions={true}
              sx={{
                "& .ql-toolbar": {
                  backgroundColor: "white",
                },
                "& .ql-container": {
                  backgroundColor: "white",
                },
              }}
            ></Chat>
          )}
        </Box>
      </SideDetailsContainer>
    </Drawer>
  );
};

export default ChatDrawer;
