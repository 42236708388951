import {
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { FC, useMemo } from "react";
import { ProcessExecution } from "../../../model/ProcessExecution";
import User from "../../../model/User";
import ProcessExecutionUpdated from "./ProcessExecutionStarted";
import ProcessProgress from "./ProcessProgress";
import { AccountLink } from "../../accounts/components";
import { getUserFullName } from "../../activity-log";
import { useGlobalUserContext } from "../../../hooks/useGlobalUserContext";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";
import { RenderTaskTitle } from "../../../hooks/useGlobalRoleContext";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { ProcessExecutionAPI } from "../../../api";
import { ProcessExecutionCell } from "./execution";
import { UserIcon } from "../../../components/elements";

export type ProcessExecutionDataGridProps =
  | DataGridProps
  | {
      columns?: GridColDef[]; //optional since a set of columns is already used as default
      rows: ProcessExecution[];
    };

const UUID_REGEX = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
);
const isUuid = (val?: string) => {
  return val && val.toUpperCase().match(UUID_REGEX);
};
const DueDate: FC<{ date: string | undefined }> = ({ date }) => {
  if (!date) {
    return <></>;
  }
  return <Typography variant="body1">{dayjs.utc(date).format("L")}</Typography>;
};

export const DEFAULT_GRID_COLUMNS = {
  ID: {
    field: "id",
    headerName: "ID",
    sortable: true,
    flex: 1,
  },
  TITLE: {
    field: "name",
    headerName: "Title",
    sortable: false,
    disableColumnMenu: true,
    flex: 1.5,
    minWidth: 200,
    maxWidth: 1000,
    headerClassName: "process-grid-header",
    renderCell: RenderTaskTitle,
  },
  OWNER: {
    field: "owner",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    headerClassName: "process-grid-header",
    renderCell: (params: GridRenderCellParams) => {
      if (!params.row.owner) {
        return <></>;
      }
      return <UserIcon user={params.row.owner} />;
    },
  },
  ACCOUNT: {
    field: "account",
    headerName: "Account",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 160,
    width: 160,
    maxWidth: 260,
    headerClassName: "process-grid-header",
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.account_name) {
        return (
          <AccountLink
            account={{
              id: params.row.subject_id,
              account_name: params.row.account_name,
            }}
            {...params.row}
          />
        );
      }
      if (params.row.subject_id && !isUuid(params.row.subject_id)) {
        return params.row.subject_id;
      }
      return <></>;
    },
  },
  PROCESS: {
    field: "process",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    maxWidth: 450,
    headerClassName: "process-grid-header",
    renderCell: ProcessExecutionCell,
  },
  PROGRESS: {
    field: "progress",
    headerName: "Progress",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 110,
    maxWidth: 150,
    headerClassName: "process-grid-header",
    renderCell: (params: GridRenderCellParams) => {
      return <ProcessProgress processExecution={params.row} />;
    },
  },
  DUE_DATE: {
    field: "due_date",
    type: "date",
    headerName: "Due",
    sortable: false,
    editable: true,
    disableColumnMenu: true,
    width: 130,
    flex: 1,
    minWidth: 110,
    maxWidth: 130,
    headerClassName: "process-grid-header",
    renderCell: (params: GridRenderCellParams) => {
      return <DueDate date={params.value} />;
    },
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.value) {
        return undefined;
      }
      const d = dayjs.utc(params.value);
      return new Date(d.year(), d.month(), d.date());
    },
  },
  UPDATED: {
    field: "updated_at",
    type: "date",
    headerName: "Updated",
    sortable: false,
    disableColumnMenu: true,
    width: 130,
    flex: 1,
    minWidth: 110,
    maxWidth: 150,
    headerClassName: "process-grid-header",
    renderCell: (params: GridRenderCellParams) => {
      return <ProcessExecutionUpdated {...params.row} />;
    },
    valueGetter: (params: GridValueGetterParams) => new Date(params.value),
  },
  ASSIGNEE: (user: User) => {
    return {
      field: "assignee",
      headerName: "Assigned",
      flex: 1,
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "process-grid-header",
      renderCell: (params: GridRenderCellParams) => {
        const assignee: User | undefined = params.row.next_task_instance
          ? params.row.next_task_instance.assignee
          : params.row.owner;
        if (assignee) {
          const name = getUserFullName(assignee);
          if (user.id === assignee.id) {
            return <b>{name}</b>;
          }
          return name;
        }
        return <i>{params.row.next_task_instance?.role?.name}</i> || "";
      },
    };
  },
};

const ProcessExecutionDataGrid: FC<ProcessExecutionDataGridProps> = (props) => {
  const { user } = useGlobalUserContext();
  const assigneeColumn = useMemo(() => {
    return DEFAULT_GRID_COLUMNS.ASSIGNEE(user);
  }, [user]);
  return (
    <StyledDataGrid
      data-cy="process-execution-data-grid"
      checkboxSelection
      disableColumnSelector
      hideFooterSelectedRowCount
      processRowUpdate={async (updatedRow) => {
        // Update process execution due date
        await ProcessExecutionAPI.update(updatedRow.id, {
          due_date: updatedRow.due_date,
        });
        return updatedRow;
      }}
      columns={[
        DEFAULT_GRID_COLUMNS.ID,
        DEFAULT_GRID_COLUMNS.TITLE,
        DEFAULT_GRID_COLUMNS.ACCOUNT,
        DEFAULT_GRID_COLUMNS.PROGRESS,
        assigneeColumn,
        DEFAULT_GRID_COLUMNS.UPDATED,
      ]}
      autoPageSize
      {
        ...props /* overwrites default props based on what's passed in*/
      }
    />
  );
};

export default ProcessExecutionDataGrid;
