import { FC, useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  Task,
  TaskForm,
  TaskFormExecution,
  HeadingFormElement,
  FormElementType,
  TaskExecution,
  TaskType,
  TaskExecutionRenderProps,
} from "../../task";
import { ProcessExecution } from "../../../model/ProcessExecution";
import Tasks from "../../../api/tasks";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { useAlert } from "../../../lib/alert";
import AnchorSectionLinkComponent from "../../task/components/task-types/form/task-form-elements/AnchorSectionLinkComponent";
import PATHS from "../../../components/navigation/_paths";
import { PageHeader } from "../../../components/layout/page";
import useLinks from "../../../hooks/useLinks";
import NotFound from "../../app/NotFound";
import { ProcessExecutionProvider, useProcessExecutionContext } from "../hooks";
import useProcessExecution from "../hooks/useProcessExecution";
import FormExecutionComponent from "../../task/components/task-types/form/FormExecutionComponent";

type LongTaskFormRoute = {
  processId: string;
  processExecutionId: string;
  taskId: string;
};

type FormControlHeaderProps = {
  execution: TaskExecution;
  processExecution: ProcessExecution;
};

const FormControlHeader = ({
  execution,
  processExecution,
}: FormControlHeaderProps) => {
  const links = useLinks();
  return (
    <>
      <PageHeader
        title={execution.name}
        links={[
          links.EXECUTIONS,
          {
            title: processExecution.name,
            link: links.EXECUTION.linkTo(
              processExecution.process_id,
              processExecution.id,
            ),
          },
        ]}
      />
    </>
  );
};

const ProcessExecutionLongTaskFormPage: FC<unknown> = () => {
  const navigate = useNavigate();
  const { processId, processExecutionId, taskId } =
    useParams<LongTaskFormRoute>();
  const { info, error } = useAlert();
  const processExecutionValues = useProcessExecution(processExecutionId ?? "");
  const { processExecution, taskExecutions } = processExecutionValues;

  const [task, setTask] = useState<TaskForm>();
  const [taskExecution, setTaskExecution] = useState<TaskFormExecution>();

  const [anchoredHeaders, setAnchoredHeaders] = useState<
    Array<HeadingFormElement>
  >([]);

  useDisplayOptions({
    showDrawer: false,
    showTopBorder: true,
    topBorderColor: processExecution?.display?.fillColor,
  });

  const checkForAnchoredHeaders = (task: Task) => {
    const anchoredHeaders: Array<HeadingFormElement> = [];
    (task as TaskForm).data?.form?.elements?.forEach((formElement) => {
      if (
        formElement.type == FormElementType.Heading &&
        formElement.isAnchored
      ) {
        anchoredHeaders.push(formElement);
      }
    });
    setAnchoredHeaders(anchoredHeaders);
  };

  const fetchTask = useCallback(
    (processId?: string, taskId?: string): Promise<Task> => {
      if (!processId || !taskId) {
        info(`Failed to fetch task due to missing processId/taskId`);
        return Promise.resolve({
          process_id: "",
          name: "",
          task_identifier_name: "",
        });
      }
      return Tasks.get(processId, taskId).then(
        (task: Task) => {
          if (task?.type == TaskType.Form) {
            checkForAnchoredHeaders(task);
          }
          setTask(task as TaskForm);
          return task;
        },
        () => {
          error("Could not load task for process");
          return { process_id: "", name: "", task_identifier_name: "" };
        },
      );
    },
    [info, error],
  );

  useEffect(() => {
    fetchTask(processId, taskId);
    const execution = taskExecutions.find(
      (execution) => execution?.task_id === taskId,
    );
    setTaskExecution(execution as TaskForm);
  }, [fetchTask, processId, taskExecutions, taskId]);

  const FormContent = () => {
    const { executeTask } = useProcessExecutionContext();

    const returnToProcessExecutionScreen = async (
      submission: TaskFormExecution,
      completeTask?: boolean,
    ) => {
      await executeTask(submission, completeTask);
      if (!task || !taskExecution || !completeTask) {
        return;
      }
      navigate(
        PATHS.EXECUTION.linkTo(
          task.process_id!,
          taskExecution.process_execution_id!,
        ),
      );
    };

    return (
      <Box
        display={"flex"}
        height={"100%"}
        justifyContent={"center"}
        flex={1}
        overflow={"auto"}
        p={3}
      >
        <FormExecutionComponent
          task={task as TaskForm}
          taskExecution={taskExecution as TaskFormExecution}
          processExecution={processExecution}
          onExecute={returnToProcessExecutionScreen}
        >
          {({ actions, content }: TaskExecutionRenderProps) => {
            return (
              <Box
                flex={1}
                display={"flex"}
                flexDirection="column"
                overflow={"hidden"}
                py={2}
              >
                <Box
                  overflow={"auto"}
                  display={"flex"}
                  flex={1}
                  justifyContent={"center"}
                >
                  <Box width={"80%"}>{content}</Box>
                </Box>
                <Box mt={1} display={"flex"} flex={0} justifyContent={"center"}>
                  <Box width={"80%"}>{actions}</Box>
                </Box>
              </Box>
            );
          }}
        </FormExecutionComponent>
      </Box>
    );
  };

  if (!processExecutionId) {
    return <NotFound />;
  }

  if (!taskId) {
    return (
      <Navigate
        to={
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          PATHS.EXECUTION.linkTo(processId!, processExecutionId)
        }
      ></Navigate>
    );
  }
  return (
    <ProcessExecutionProvider value={processExecutionValues}>
      {taskExecution && processExecution && (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <FormControlHeader
            execution={taskExecution}
            processExecution={processExecution}
          />
          <AnchorSectionLinkComponent headerLinks={anchoredHeaders} />
          <FormContent />
        </Box>
      )}
    </ProcessExecutionProvider>
  );
};

export default ProcessExecutionLongTaskFormPage;
