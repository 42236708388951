import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { MainContent, SideNavigation, TopBar } from "..";
import { SideNavigationProps } from "../../../types";
import { styled } from "@mui/material/styles";
import { environment } from "../../../util";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import AccountButton from "../top-bar/components/AccountButton";
import Scrollable from "../Scrollable";

export const TOP_NAV_ENTERPRISE_ACCOUNT_HEIGHT = 8;

export type PageAreaProps = {
  drawerWidth?: number;
  open?: boolean;
};
const PageArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<PageAreaProps>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${theme.spacing(1)}`,
  ...(open && {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));
const isEnterprise = !!environment.organization?.key;
export const DashboardLayout: FC<PropsWithChildren<SideNavigationProps>> = ({
  children,
  ...props
}) => {
  const { organizations, organizationsLoaded } = useGlobalOrganizationContext();
  const isAdmin = organizations.find(
    (org) => org.key === environment.organization?.key,
  );
  const topNavHeight =
    isEnterprise && !isAdmin ? TOP_NAV_ENTERPRISE_ACCOUNT_HEIGHT : 0;
  if (!organizationsLoaded) {
    return <></>;
  }
  return (
    <Box id="dashboard-layout" sx={{ display: "flex", height: "100%" }}>
      {!isEnterprise && <SideNavigation offset={topNavHeight} {...props} />}
      <Scrollable
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
        }}
      >
        {/* Top bar is shown only on account portal pages for account user */}
        <TopBar height={topNavHeight} />
        <MainContent top={topNavHeight > 0 ? topNavHeight + 2 : 0}>
          <Box position="absolute" right="10px" top="10px" zIndex={1299}>
            <AccountButton />
          </Box>
          <PageArea
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: "auto",
              flex: 1,
            }}
          >
            <Box
              component={"section"}
              sx={{
                ml: isEnterprise ? "0" : "56px",
                flex: 1,
                overflow: "auto",
                "@media print": {
                  ml: 0,
                },
              }}
            >
              {children}
            </Box>
          </PageArea>
        </MainContent>
      </Scrollable>
    </Box>
  );
};

export default DashboardLayout;
